import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal, Table, Pagination } from "react-bootstrap";
import BankAccountService from "../../../../../services/bankAccount.service";
import { enqueueSnackbar } from "notistack";
import WalletData from "../../../../../services/wallet.service";
import { Chart as ChartJS, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(...registerables);

const Wallet = () => {
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);
  const [bankData, setBankData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  const localData = JSON.parse(localStorage.getItem("userProfile"));
  const facilityID = localData.facility_id;

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const getBankTypeData = async () => {
    try {
      setLoading(true);
      const response = await BankAccountService.getBankType();
      setBankData(response.data["data"]);
      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      console.log("GetAll PitchType error", error);
      setLoading(false);
      setLoadingData(false);
    }
  };

  const GetWalletListData = async () => {
    if (facilityID) {
      setLoadingData(true);
      const response = await WalletData.GetWalletListData(facilityID);
      const transformedData = Object.keys(response.data.data).map((date) => ({
        date,
        revenue: response.data.data[date],
      }));
      setData(transformedData);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getBankTypeData();
  }, []);

  useEffect(() => {
    GetWalletListData();
  }, [facilityID]);

  const mergedTransactions = [...withdrawalHistory];

  mergedTransactions.sort((a, b) => new Date(b.date) - new Date(a.date));

  const chartData = Object.keys(data).map((date) => ({
    x: date,
    y: data[date],
  }));

  const chartdata = {
    labels: chartData.map((data) => data?.y?.date),
    datasets: [
      {
        label: "Revenue",
        data: chartData.map((data) => data?.y?.revenue),
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  // Options for the chart
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="page-header">
        <h2>Wallet</h2>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          {data && data.length > 0 ? (
            <Card style={{ marginBottom: "20px" }}>
              <Card.Body>
                <Table striped bordered hover>
                  <thead style={{ backgroundColor: "#0DB2F0" }}>
                    <tr>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Id
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Weekly Dates
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Weekly Revenue
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {data.map((transaction, index) => {
                      return (
                        <tr key={index}>
                          <td
                            style={{
                              textAlign: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {index + 1}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {transaction.date}
                          </td>
                          <td
                            style={{
                              textAlign: "center",
                              fontWeight: "bolder",
                            }}
                          >
                            {transaction.revenue}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            ""
          )}

          <Card>
            <Card.Header style={{ display: "flex" }}>
              <h4>Accounts Details</h4>
            </Card.Header>
            <Card.Body>
              {loadingData ? (
                <div style={{ textAlign: "center" }}>
                  <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Loading...
                  </h3>
                </div>
              ) : data && data.length > 0 ? (
                <>
                  {/* Display line chart */}
                  <div>
                    <Line data={chartdata} options={options} />
                  </div>
                </>
              ) : (
                <div style={{ textAlign: "center" }}>
                  <strong>No data available</strong>
                </div>
              )}
            </Card.Body>
          </Card>
        </div>
      </div>
    </>
  );
};

export default Wallet;
