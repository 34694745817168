import serverCall from "../serverCall";

//getFaqDataAll
const getFqqQuestion = async (page) => {
  try {
    const response = await serverCall.get(
      `/faq/getall?page=${page}&limit=${12}`
    );
    return response;
  } catch (error) {
    console.log("get map error", error);
  }
};

//CreateFaqAddQuestion
const CreateFaqAddQuestion = async (name) => {
  const response = await serverCall.post(`/faq/admin/create`, name);
  return response;
};

//GetFaqSingleId
const GetFaqSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/faq/${id}`);
    return response;
  } catch (error) {
    console.log("GetFaqSingleId Api error", error);
  }
};

//DeleteFaqDataRequest
const DeleteFaqDataRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/faq/admin/delete/${id}`);
    return response;
  } catch (error) {
    console.log("DeleteFaqId Api error", error);
  }
};

//UpdateFaqDataRequest
const UpdateFaqDataRequest = async (id, name) => {
  try {
    const response = await serverCall.put(`/faq/admin/update/${id}`, name);
    return response;
  } catch (error) {
    console.log("GetFaqSingleId Api error", error);
  }
};

const FaqData = {
  getFqqQuestion,
  CreateFaqAddQuestion,
  DeleteFaqDataRequest,
  UpdateFaqDataRequest,
  GetFaqSingleId,
};

export default FaqData;
