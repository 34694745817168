import React, { useState, useEffect } from "react";
import { Button, Col, Form, Modal } from "react-bootstrap";
import GameData from "../../../../../services/game.service";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@material-ui/core";
import TimeSelector from "./TimeSlot";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import UserListData from "../../../../../services/userList.service";

const BookFullPitch = ({
  show,
  handleClose,
  handlePlayerSelect,
  player,
  handleFullPitchSubmit,
  gameDetail,
  selectedPlayer,
  setSelectedPlayer,
  getGameSettingsData,
  setIsFullPitchModalShow,
  info,
}) => {
  const [selectedDays, setSelectedDays] = useState([]);
  const [totalWeeks, setTotalWeeks] = useState(1);
  const [hours, setHours] = useState(1);
  const [weekError, setWeekError] = useState("");
  const [dateList, setDateList] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [isWeekLoading, setIsWeekLoading] = useState(false);
  const [playerError, setPlayerError] = useState("");
  const [daysError, setDaysError] = useState("");
  const [allGamePrice, setAllGamePrice] = useState("");
  const [isDeposite, setIsDeposite] = useState("");
  const [notes, setNotes] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedData, setSearchedData] = useState([]);
  const [isEnterMouse, setIsEnterMouse] = useState(true);
  const [orderedDays, setOrderedDays] = useState([]);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  // price change
  const [editIndex, setEditIndex] = useState(null); // To track which item is being edited
  const [tempPrice, setTempPrice] = useState(""); // For storing the temporary price value

  const handleEditClick = (index, price) => {
    setEditIndex(index);
    setTempPrice(price);
  };

  const handlePriceChange = (e) => {
    setTempPrice(e.target.value);
  };

  const handleBlur = () => {
    if (editIndex !== null) {
      const updatedDateList = dateList.map((item, i) =>
        i === editIndex ? { ...item, price: tempPrice } : item
      );
      setDateList(updatedDateList);
      setEditIndex(null);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleBlur();
    }
  };

  const handleClickOutside = (e) => {
    // To check if the click was outside the input field
    if (e.target.closest('input[type="text"]') === null) {
      handleBlur();
    }
  };

  // Add event listener for clicks outside
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const currentDay = new Date(gameDetail?.gameDate).getDay();
    const reorderedDays = [
      ...days.slice(currentDay),
      ...days.slice(0, currentDay),
    ];
    setOrderedDays(reorderedDays);
    setSelectedDays([days[currentDay]]);
  }, [gameDetail]);

  const handleDaySelect = (day) => {
    setSelectedDays((prevDays) => {
      if (prevDays.includes(day)) {
        return prevDays.filter((d) => d !== day);
      } else {
        return [...prevDays, day];
      }
    });
  };

  const handleHours = (hour) => {
    setHours(hour);
  };

  function formatTimeRange(startDateTime, endDateTime) {
    const formatTime = (dateString, includePeriod = true) => {
      const date = new Date(dateString);

      // UTC
      // let hours = moment.utc(new Date(dateString)).format("hh");
      // const minutes = moment.utc(new Date(dateString)).format("mm");

      let hours = date.getHours();
      const minutes = date.getMinutes();

      // Convert to 12-hour format and determine AM/PM
      const period = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert 0 to 12

      // Format minutes to always be two digits
      const formattedMinutes = minutes.toString().padStart(2, "0");

      return includePeriod
        ? `${hours}:${formattedMinutes} ${period}`
        : `${hours}:${formattedMinutes}`;
    };

    const startTime = formatTime(startDateTime, false);
    const endTime = formatTime(endDateTime);

    const startPeriod = new Date(startDateTime).getHours() >= 12 ? "PM" : "AM";
    const endPeriod = new Date(endDateTime).getHours() >= 12 ? "PM" : "AM";

    console.log(
      "moment(startDateTime",
      moment.utc(startDateTime).format("hh:mm a")
    );
    // 1:00 to 2:00 PM

    return startPeriod === endPeriod
      ? `${startTime} to ${endTime}`
      : `${startTime} ${startPeriod} to ${endTime}`;
  }

  const increaseWeeks = () => {
    if (totalWeeks < 52) {
      setTotalWeeks(Number(totalWeeks) + 1);
    }
  };

  const decreaseWeeks = () => {
    if (totalWeeks > 1) {
      setTotalWeeks(Number(totalWeeks) - 1);
    }
  };

  const handleweekChange = (e) => {
    const input = e.target.value;
    if (input > 52) {
      setWeekError("Maximum 52 weeks allowed");
    } else {
      setWeekError("");
    }

    const numericValue = Math.max(0, Math.min(52, parseInt(input) || 0));
    setTotalWeeks(numericValue);
  };

  const getRepeatedList = async () => {
    setIsWeekLoading(true);
    try {
      let selected = [];
      selectedDays.forEach((selectedDay) => {
        const dayIndex = days.indexOf(selectedDay);
        selected.push(dayIndex.toString());
      });

      const body = {
        gameId: gameDetail._id,
        startTime: gameDetail.startTime,
        endTime: gameDetail.endTime,
        numberOfWeeks: totalWeeks,
        numberOfHours: hours,
        selectedDays: selected,
      };
      const response = await GameData.availableRepeated(body);
      if (response) setIsDeposite(response?.data?.isDeposit);
      console.log("day", response.data.data);
      generateDateList(response.data.data);
    } catch (e) {
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } finally {
      setIsWeekLoading(false);
    }
  };

  // const generateDateList = (repeated) => {
  //   const newDateList = [];
  //   const newSelectedDates = [];

  //   selectedDays.forEach((selectedDay) => {
  //     let startDate = new Date(gameDetail.gameDate);
  //     const dayIndex = orderedDays.indexOf(selectedDay);
  //     const currentDayIndex = orderedDays.indexOf(
  //       moment(startDate).format("ddd")
  //     );

  //     while (currentDayIndex !== dayIndex) {
  //       startDate.setDate(startDate.getDate() + 1);
  //     }

  //     for (let i = 0; i < totalWeeks; i++) {
  //       const currentDate = new Date(startDate);
  //       currentDate.setDate(startDate.getDate() + i * 7);

  //       const formattedDate = currentDate.toLocaleDateString("en-GB", {
  //         weekday: "long",
  //         day: "numeric",
  //         month: "long",
  //       });

  //       const matchingDate = repeated.find((item) => {
  //         const itemDate = new Date(item.startTime);
  //         return itemDate.toDateString() === currentDate.toDateString();
  //       });

  //       const isAvailable = matchingDate ? matchingDate.available : true;
  //       const id = matchingDate ? matchingDate._id : null;
  //       const price = matchingDate ? matchingDate.friendlyprice || 0 : 0;
  //       const timeRange = formatTimeRange(
  //         matchingDate?.startDateTime,
  //         matchingDate?.endDateTime
  //       );

  //       newDateList.push({
  //         date: formattedDate,
  //         available: isAvailable ? "available" : "not available",
  //         id: id,
  //         price,
  //         timeRange,
  //       });

  //       if (isAvailable) {
  //         newSelectedDates.push({ date: formattedDate, id: id });
  //       }
  //     }
  //   });

  //   newDateList.sort((a, b) => new Date(a.date) - new Date(b.date));

  //   setDateList(newDateList);
  //   setSelectedDates(newSelectedDates);
  // };

  const generateDateList = (repeated) => {
    const newDateList = [];
    const newSelectedDates = [];

    selectedDays.forEach((selectedDay) => {
      let startDate = new Date(gameDetail.gameDate);
      const dayIndex = orderedDays.indexOf(selectedDay);
      let currentDayIndex = orderedDays.indexOf(
        moment(startDate).format("ddd")
      );

      // Calculate the number of days to add to reach the selected day
      const daysToAdd = (dayIndex - currentDayIndex + 7) % 7;
      startDate.setDate(startDate.getDate() + daysToAdd);

      for (let i = 0; i < totalWeeks; i++) {
        const currentDate = new Date(startDate);
        currentDate.setDate(startDate.getDate() + i * 7);

        const formattedDate = currentDate.toLocaleDateString("en-GB", {
          weekday: "long",
          day: "numeric",
          month: "long",
        });

        const matchingDate = repeated.filter((item) => {
          const itemDate = new Date(item.startTime);
          return itemDate.toDateString() === currentDate.toDateString();
        });

        matchingDate.map((item) => {
          const isAvailable = item ? item.available : true;
          const id = item ? item._id : null;
          const price = item ? item.friendlyprice || 0 : 0;
          const timeRange = formatTimeRange(
            item?.startDateTime,
            item?.endDateTime
          );

          newDateList.push({
            date: formattedDate,
            available: isAvailable ? "available" : "not available",
            id: id,
            price,
            timeRange,
          });

          if (isAvailable) {
            newSelectedDates.push({ date: formattedDate, id: id });
          }
        });
      }
    });

    newDateList.sort((a, b) => new Date(a.date) - new Date(b.date));

    setDateList(newDateList);
    setSelectedDates(newSelectedDates);
  };

  const handleAvailableChange = (e, item) => {
    if (item.available === "available") {
      if (e.target.checked) {
        setSelectedDates((prev) => [...prev, { date: item.date, id: item.id }]);
      } else {
        setSelectedDates((prev) =>
          prev.filter((dateObj) => dateObj.date !== item.date)
        );
      }
    }
  };

  const handleBookPitch = async () => {
    try {
      const ids = selectedDates.map((e) => {
        return {
          gameId: e.id,
          repeatPrice: dateList?.find((value) => value.id == e.id)?.price,
        };
      });
      if (info === "RepeatedBooking") {
        const body = {
          status: "AHEAD",
          jointype: "REPEATED",
          player: selectedPlayer,
          allgamePrice: Number(allGamePrice),
          games: ids,
          notes,
          isDeposit: isDeposite,
        };

        if (!selectedPlayer || !totalWeeks || selectedDays == "") {
          if (!selectedPlayer) {
            setPlayerError("Please Select Player");
          }
          if (!totalWeeks) {
            setWeekError("Please Select Week");
          }
          if (selectedDays == "") {
            setDaysError("Please Select Days");
          }
          return;
        }

        // await GameData.updateMultiple(body);
        await GameData.newUpdateMultiple(body);
      } else {
        handleFullPitchSubmit();
      }

      setPlayerError("");
      setSelectedPlayer("");
      setAllGamePrice("");
      setNotes("");
      setIsDeposite("");
      getGameSettingsData();
      setIsFullPitchModalShow(false);
    } catch (e) {
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setPlayerError("");
      setSelectedPlayer("");
      getGameSettingsData();
      setIsFullPitchModalShow(false);
    }
  };

  useEffect(() => {
    getRepeatedList();
  }, [selectedDays, totalWeeks, hours]);

  useEffect(() => {
    getUserList("");
  }, []);

  const getUserList = async (search) => {
    const response = await UserListData.getUserListData(search, "", "");
    const body = response.data.data
      .map((item) => [
        {
          value: item._id,
          label: item.firstName + " " + item.lastName + `  (${item.email})`,
        },
      ])
      .flat();
    setSearchedData(body);
  };

  const handleChangeName = async (e) => {
    setIsEnterMouse(false);
    const input = e.target.value;
    setSearchText(input);
    getUserList(input);
  };

  const playerClick = (player) => {
    setIsEnterMouse(true);
    handlePlayerSelect(player.value);
    setSearchText(player.label);
  };

  const handlePricePerWeekChange = (e) => {
    const input = e.target.value;
    setAllGamePrice(input);
    if (input !== "") {
      const updatedDateList = dateList.map((item, i) => {
        return { ...item, price: input };
      });
      setDateList(updatedDateList);
    } else {
      getRepeatedList();
    }
  };

  return (
    <Modal centered backdrop="static" show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {gameDetail.pitch} - {gameDetail.pitchtype}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="selectPlayer">
          <Form.Label>Select Player</Form.Label>

          {/* <Form.Control
            as="select"
            onChange={(e) => handlePlayerSelect(e.target.value)}
          >
            <option value="">Select Player</option>
            {player &&
              player.map((item, index) => (
                <option key={index} value={item.value}>
                  {" "}
                  {item.label}
                </option>
              ))}
          </Form.Control> */}
          <input
            type="text"
            style={{
              width: "100%",
              borderRadius: "8px",
              outline: "none",
              border: "1px solid",
              padding: "5px 10px",
            }}
            value={searchText}
            onChange={handleChangeName}
            onMouseDown={getUserList}
          />
          {!isEnterMouse && searchedData != "" && (
            <div
              style={{
                position: "absolute",
                maxHeight: "300px",
                background: "#e9e9e9",
                width: "90%",
                // paddingBottom: "10px",
                // paddingTop: "10px",
                overflow: "auto",
                borderRadius: "10px",
                // borderBottomLeftRadius: "10px",
                marginTop: "10px",
                zIndex: 10,
              }}
            >
              {searchedData.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      cursor: "pointer",
                      padding: "10px",
                      marginBottom: "4px",
                      marginTop: "4px",
                    }}
                    className="user-list"
                    onClick={() => playerClick(item)}
                  >
                    <p className="m-0">{item.label}</p>
                  </div>
                );
              })}
            </div>
          )}
          {!selectedPlayer && (
            <div className="mt-2 tx-12 text-danger">{playerError}</div>
          )}
        </Form.Group>

        {info === "RepeatedBooking" && (
          <div>
            <Form.Group controlId="selectDays" className="mt-3">
              <Form.Label>Any other days?</Form.Label>
              <div className="d-flex w-full justify-content-between">
                {orderedDays.map((item, index) => (
                  <div
                    key={index}
                    className="px-3 py-2"
                    style={{
                      border: "1px solid black",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundColor: selectedDays.includes(item)
                        ? "white"
                        : "#e9ecef",
                      borderColor: selectedDays.includes(item)
                        ? "#28a745"
                        : "#e9ecef",
                      border: "2px solid",
                    }}
                    onClick={() => handleDaySelect(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
            </Form.Group>
            {selectedDays == "" && (
              <div className="mt-2 tx-12 text-danger">{daysError}</div>
            )}

            <Form.Label className="mt-3">How many hours</Form.Label>
            <div>
              <TimeSelector handleHours={handleHours} />
            </div>

            <div className="mt-3">52 weeks maximum</div>
            <div className="d-flex align-items-center gap-2 mt-1">
              <input type="radio" checked /> Choose a number of weeks
            </div>

            <Form.Group className="mt-3">
              <Form.Label>Notes</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Form.Group>

            <div className="mt-3">
              <Form.Label>Price per week</Form.Label>
              <Form.Control
                type="number"
                name="allgamePrice"
                value={allGamePrice}
                onChange={handlePricePerWeekChange}
              />
            </div>

            <Form.Group controlId="selectWeeks" className="mt-3">
              <div className="d-flex align-items-center mb-2 gap-2">
                <Form.Label className="mb-0">For how many weeks?</Form.Label>
                {isWeekLoading && (
                  <CircularProgress style={{ width: "15px", height: "15px" }} />
                )}
              </div>
              <div className="d-flex w-full justify-content-between gap-2">
                <Button
                  style={{ width: "100%" }}
                  onClick={decreaseWeeks}
                  disabled={totalWeeks <= 1}
                >
                  -
                </Button>
                <input
                  type="text"
                  style={{ width: "100%", textAlign: "center" }}
                  value={totalWeeks}
                  onChange={handleweekChange}
                />
                <Button
                  style={{ width: "100%" }}
                  onClick={increaseWeeks}
                  disabled={totalWeeks >= 52}
                >
                  +
                </Button>
              </div>
              {weekError && (
                <div className="mt-2 tx-12 text-danger">{weekError}</div>
              )}
            </Form.Group>
            <div
              className="mt-3"
              style={{ maxHeight: "150px", overflowY: "scroll" }}
            >
              {dateList.map((item, index) => {
                return (
                  <div key={index} className="mb-1">
                    <input
                      type="checkbox"
                      checked={
                        item.available === "available" &&
                        selectedDates.some(
                          (dateObj) => dateObj.date === item.date
                        )
                      }
                      disabled={item.available !== "available"}
                      onChange={(e) => handleAvailableChange(e, item)}
                    />

                    <span
                      style={{
                        marginLeft: "10px",
                        color:
                          item.available === "not available"
                            ? "#ff3030"
                            : "black",
                      }}
                    >
                      {item.date}({item?.timeRange})
                    </span>
                    {item.price !== 0 && item.available === "available" && (
                      <span className="px-4 d-flex align-items-center">
                        <strong>Price:</strong>
                        {editIndex === index ? (
                          <>
                            <input
                              type="text"
                              value={tempPrice}
                              onChange={handlePriceChange}
                              onBlur={handleBlur}
                              onKeyDown={handleKeyDown}
                              className="border rounded px-1"
                              style={{ width: "70px" }}
                            />{" "}
                          </>
                        ) : (
                          <div className="d-flex align-items-center ms-1">
                            {item.price}{" "}
                            <button
                              onClick={() => handleEditClick(index, item.price)}
                              className="btn btn-main-primary btn-sm ms-1 me-1"
                            >
                              <i className="fa fa-pencil"></i>
                            </button>
                          </div>
                        )}
                      </span>
                    )}

                    {item.available === "not available" && (
                      <div style={{ marginLeft: "25px", color: "#ff3030" }}>
                        not available
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        {/* <Button
          variant="primary"
          onClick={handleClose}
          style={{ borderRadius: "10px", width: "100%" }}
        >
          Next
        </Button> */}
        <Button
          variant="secondary"
          onClick={handleClose}
          style={{ borderRadius: "10px" }}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleBookPitch}
          style={{ borderRadius: "10px" }}
        // disabled={dateList?.find((res) => res?.available === "not available")}
        >
          Book
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default BookFullPitch;
