import serverCall from "../serverCall";

//getTeamData
const getTeamData = async (page, dataPerPage) => {
  try {
    const response = await serverCall.get(
      `/team/getall/admin?page=${page}&limit=${dataPerPage}`
    );
    return response;
  } catch (error) {
    console.log("get TeamData error", error);
  }
};

//GetTeamSingleId
const GetTeamSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/team/get/${id}`);
    return response;
  } catch (error) {
    console.log("GetTeamSingleId Api error", error);
  }
};

// UpdateCityDataRequest
const UpdateTeamRequest = async (id, name) => {
  try {
    const response = await serverCall.put(`/team/${id}`, { name });
    return response;
  } catch (error) {
    console.log("UpdateTeamRequest Api error", error);
  }
};

//DeleteTeamDataRequest
const DeleteTeamDataRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/team/delete/admin?teamid=${id}`);
    return response;
  } catch (error) {
    console.log("DeleteTeamDataRequest Api error", error);
  }
};

const TeamData = {
  getTeamData,
  GetTeamSingleId,
  DeleteTeamDataRequest,
  UpdateTeamRequest,
};

export default TeamData;
