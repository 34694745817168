import serverCall from "../serverCall";

//getCityData
const getCityData = async () => {
  try {
    const response = await serverCall.get(`/city/admin/getall`);
    return response;
  } catch (error) {
    console.log("get get CityDataApi error", error);
  }
};

//CreateCityData
const CreateCityData = async (payload) => {
  try {
    const response = await serverCall.post(`/city/admin/create`, payload);
    return response;
  } catch (error) {
    console.log("getCreateCityDataApi error", error);
  }
};

//GetCitySingleId
const GetCitySingleId = async (id) => {
  try {
    const response = await serverCall.get(`/city/admin/get/${id}`);
    return response;
  } catch (error) {
    console.log("getCitySingleId Api error", error);
  }
};

//UpdateCityDataRequest
const UpdateCityDataRequest = async (id, name, isPublish) => {
  try {
    const response = await serverCall.put(`/city/admin/update/${id}`, {
      name,
      isPublish,
    });
    return response;
  } catch (error) {
    console.log("UpdateCityData Api error", error);
  }
};

//DeleteCityDataRequest
const DeleteCityDataRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/city/admin/delete/${id}`);
    return response;
  } catch (error) {
    console.log("DeleteCityData Api error", error);
  }
};

//CityIDForFacility
const CityIDForFacility = async (id) => {
  try {
    const response = await serverCall.get(`/city/admin/facilities/${id}`);
    return response;
  } catch (error) {
    console.log("CityIDForFacility Api error", error);
  }
};

const CityData = {
  getCityData,
  CreateCityData,
  GetCitySingleId,
  UpdateCityDataRequest,
  DeleteCityDataRequest,
  CityIDForFacility,
};

export default CityData;
