import serverCall from "../serverCall";

//getUserListData
const getUserListData = async (search, page, dataPerPage, userType, dateFilter) => {
  try {
    const response = await serverCall.post(
      `/admin/user/get-all?page=${page}&limit=${dataPerPage}&search=${search}&userType=${userType || ""}`, { dateFilter }
    );
    return response;
  } catch (error) {
    console.log("get UserListData error", error);
  }
};

//CreateUserList
const CreateUserList = async (name) => {
  try {
    const response = await serverCall.post(`/admin/user-create`, name);
    return response;
  } catch (error) {
    console.log("getCreateCityDataApi error", error);
  }
};

//GetUserListSingleId
const GetUserListSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/admin/user/${id}`);
    return response;
  } catch (error) {
    console.log("UserList SingleId  Api error", error);
  }
};

//UpdateUserListRequest
const UpdateUserListRequest = async (id, name) => {
  try {
    const response = await serverCall.put(`/admin/user/update/${id}`, name);
    return response;
  } catch (error) {
    console.log("UpdateCityData Api error", error);
  }
};

//DeleteUserListRequest
const DeleteUserListRequest = async (id) => {
  try {
    const response = await serverCall.delete(`/admin/user/delete/${id}`);
    return response;
  } catch (error) {
    console.log("DeleteCityData Api error", error);
  }
};

//getHostListData
const getHostListData = async (search) => {
  try {
    const response = await serverCall.get(
      `/admin/host/get-all?search=${search}`
    );
    return response;
  } catch (error) {
    console.log("get UserListData error", error);
  }
};

//getAdminListData
const getAdminListData = async (search) => {
  try {
    const response = await serverCall.get(
      `/admin/get-all-admin?search=${search}`
    );
    return response;
  } catch (error) {
    console.log("get UserListData error", error);
  }
};

//GetUserTransactionSingleId
const GetUserTransactionSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/transactions/get-history/${id}`);
    return response;
  } catch (error) {
    console.log("UserTransactionList SingleId Api error", error);
  }
};

//DeleteUserListRequest

const UserListData = {
  getUserListData,
  CreateUserList,
  GetUserListSingleId,
  UpdateUserListRequest,
  DeleteUserListRequest,
  getHostListData,
  GetUserTransactionSingleId,
  getAdminListData,
};

export default UserListData;
