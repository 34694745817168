// GameEditModal.js
import React, { useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import {
  Formik,
  ErrorMessage,
  Form as FormikForm,
  Field,
  FieldArray,
} from "formik";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";

const GameEditModal = ({
  show,
  handleClose,
  handleSave,
  isEdit,
  gameData,
  gameId,
  selectedDateRange,
}) => {
  const [selectedRange, setSelectedRange] = useState(selectedDateRange || null);

  const validationSchema = Yup.object().shape({
    dateTimeRange: Yup.string().required("Date By is required"),
    timeRange: Yup.string().required("Time Range is required"),
    hostBy: Yup.string().required("Host By is required"),
    location: Yup.string().required("Location is required"),
    rules: Yup.array().of(Yup.string().required("Rule is required")),
    important: Yup.array().of(Yup.string().required("Important is required")),
  });

  const initialValues = {
    dateTimeRange: "",
    timeRange: "",
    hostBy: "",
    location: "",
    rules: [""],
    important: [""],
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    await handleSave(values, gameId, values.timeRange); // Pass timeRange
    handleClose();
    setSubmitting(false);
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      centered
      backdrop="static"
    >
      <Modal.Header style={{ marginBottom: "0px" }} closeButton>
        <Modal.Title style={{ fontSize: "20px" }}>
          {isEdit ? "Update Game" : "Create Game"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            isEdit && gameData
              ? {
                  ...gameData,
                }
              : initialValues
          }
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, values }) => (
            <FormikForm style={{ fontWeight: "500" }}>
              <Row className="mb-3">
                <Form.Group as={Col} controlId="dateTimeRange">
                  <Form.Label style={{ color: "black" }}>Date </Form.Label>
                  <DatePicker
                    value={selectedRange}
                    placeholder="Select Date"
                    format="DD-MM-YYYY"
                    onChange={(value) => {
                      setSelectedRange(value);
                      setFieldValue(
                        "dateTimeRange",
                        value ? value.format("YYYY-MM-DD") : ""
                      );
                    }}
                    className="custom-date-picker"
                    style={{
                      borderColor: "black",
                      width: "100%",
                      height: "33px",
                    }}
                  />
                  <ErrorMessage
                    name="dateTimeRange"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
                <Form.Group as={Col} controlId="timeRange">
                  <Form.Label style={{ color: "black" }}>
                    Start - End Time
                  </Form.Label>
                  <Field
                    as="select"
                    name="timeRange"
                    className="form-select"
                    style={{ borderColor: "black", width: "50%" }}
                  >
                    <option style={{ textAlign: "center" }} value="">
                      Select Time Slot
                    </option>
                    <option style={{ textAlign: "center" }} value="9:00-10:00">
                      {" "}
                      9:00-10:00
                    </option>
                    <option style={{ textAlign: "center" }} value="10:00-11:00">
                      10:00-11:00
                    </option>
                    <option style={{ textAlign: "center" }} value="11:00-12:00">
                      11:00-12:00
                    </option>
                    <option style={{ textAlign: "center" }} value="12:00-13:00">
                      12:00-13:00
                    </option>
                    <option style={{ textAlign: "center" }} value="13:00-14:00">
                      13:00-14:00
                    </option>
                    <option style={{ textAlign: "center" }} value="14:00-15:00">
                      14:00-15:00
                    </option>
                    <option style={{ textAlign: "center" }} value="15:00-16:00">
                      15:00-16:00
                    </option>
                    <option style={{ textAlign: "center" }} value="16:00-17:00">
                      16:00-17:00
                    </option>
                    <option style={{ textAlign: "center" }} value="17:00-18:00">
                      17:00-18:00
                    </option>
                    <option style={{ textAlign: "center" }} value="18:00-19:00">
                      18:00-19:00
                    </option>
                    <option style={{ textAlign: "center" }} value="19:00-20:00">
                      19:00-20:00
                    </option>
                    <option style={{ textAlign: "center" }} value="20:00-21:00">
                      20:00-21:00
                    </option>
                    <option style={{ textAlign: "center" }} value="21:00-22:00">
                      21:00-22:00
                    </option>
                    <option style={{ textAlign: "center" }} value="22:00-23:00">
                      22:00-23:00
                    </option>
                  </Field>
                  <ErrorMessage
                    name="timeRange"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Row>

              <hr></hr>
              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  controlId="hostBy"
                  style={{ width: "50px" }}
                >
                  <Form.Label style={{ color: "black", width: "50%" }}>
                    Host By
                  </Form.Label>
                  <Field
                    type="text"
                    name="hostBy"
                    as={Form.Control}
                    placeholder="Enter host"
                    style={{ width: "50%" }}
                  />
                  <ErrorMessage
                    name="hostBy"
                    component="div"
                    className="text-danger"
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3" style={{ justifyContent: "end" }}>
                <FieldArray name="rules">
                  {({ push, remove, form }) => (
                    <>
                      <Form.Label style={{ color: "black" }}>
                        Rules of the game
                      </Form.Label>
                      <div className="rules-container">
                        {form.values.rules.map((rule, index) => (
                          <div key={index} className="rule-item">
                            <Field
                              type="text"
                              name={`rules.${index}`}
                              as={Form.Control}
                              placeholder={`Rule ${index + 1}`}
                              className="m-2"
                            />
                            <ErrorMessage
                              name={`rules.${index}`}
                              component="div"
                              className="text-danger"
                            />
                            <FontAwesomeIcon
                              icon={faTimes}
                              onClick={() => remove(index)}
                              className="remove-rule-icon"
                            />
                          </div>
                        ))}
                      </div>
                      <Button
                        size="sm"
                        style={{
                          width: "20%",
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                        type="button"
                        onClick={() => push("")}
                      >
                        Add Rule
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Row>
              <Row className="mb-3" style={{ justifyContent: "end" }}>
                <FieldArray name="important">
                  {({ push, remove, form }) => (
                    <>
                      <Form.Label style={{ color: "black" }}>
                        Important of the game
                      </Form.Label>
                      <div className="rules-container">
                        {form.values.important.map((rule, index) => (
                          <div key={index} className="rule-item">
                            <Field
                              type="text"
                              name={`important.${index}`}
                              as={Form.Control}
                              placeholder={`Important ${index + 1}`}
                              className="m-2"
                            />
                            <ErrorMessage
                              name={`important.${index}`}
                              component="div"
                              className="text-danger"
                            />
                            <FontAwesomeIcon
                              icon={faTimes}
                              onClick={() => remove(index)}
                              className="remove-rule-icon"
                            />
                          </div>
                        ))}
                      </div>
                      <Button
                        size="sm"
                        style={{
                          width: "20%",
                          marginTop: "10px",
                          marginRight: "10px",
                        }}
                        type="button"
                        onClick={() => push("")}
                      >
                        Add Important
                      </Button>
                    </>
                  )}
                </FieldArray>
              </Row>
              <Row style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  style={{ width: "40%", borderRadius: "15px" }}
                >
                  Submit
                </Button>
              </Row>
            </FormikForm>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default GameEditModal;
