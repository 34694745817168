import serverCall from "../serverCall";

const GetAllRepeatedBookings = async (facilityId) => {
  try {
    let response
    if (facilityId) {
      response = await serverCall.post("/game/admin/get-all", { facilityId });
    } else {
      response = await serverCall.post("/game/admin/get-all");
    }
    return response?.data;
  } catch (error) {
    console.log("GetAllRepeatedBookings Api error", error);
  }
};

export default GetAllRepeatedBookings;
