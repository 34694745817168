import React, { useEffect, useState } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-multi-date-picker";
import Select from "react-select";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import PitchTypeService from "../../../../../services/pitchType.service";
import PitchNameService from "../../../../../services/pitchName.service";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import { IoMdClose } from "react-icons/io";
import { enqueueSnackbar } from "notistack";

export const FORMIKERROR = {
  fontSize: "12px",
  color: "red",
  marginTop: "3px",
};

const GameSettingEditModal = (props) => {
  const {
    handleClose,
    handleSave,
    isEditMode,
    formik,
    setIsEditMode,
    handlePitchChange,
    showModal,
    selectedGameSettings,
    handlePitchNameChange,
    handlePitchTypeChange,
    formikErrors,
    addNumbers,
    setAddNumbers,
    addRules,
    setAddRules,
    isStartChange,
    isEndChange,
    setIsStartChange,
    setIsEndChange,
    setLocalStartTime,
    localStartTime,
    localEndTime,
    setLocalEndTime,
    loadingData,
    handleDateChange,
    pitchTypes,
  } = props;

  const handleModalClose = () => {
    formik.resetForm(); // Reset the form values
    handleClose();
    setIsEditMode(false)
    formik.values.pitch = []// Close the modal
  };

  useEffect(() => {
    if (!isEditMode) {
      formik.resetForm();
    }
  }, [isEditMode]);
  const [searchText, setSearchText] = useState("");

  const [pitchTypeApi, setPitchTypeApi] = useState([]);
  const [pitchNameApi, setPitchNameApi] = useState([]);
  const [changesMade, setChangesMade] = useState(false);

  const getPitchTypeData = async () => {
    try {
      const response = await PitchTypeService.getPitchType();
      setPitchTypeApi(response.data.data);
    } catch (error) {
      console.log("GetAll PitchType errrrrr", error);
    }
  };
  useEffect(() => {
    getPitchTypeData();
  }, []);

  const pitchTypesOptions = [
    ...pitchTypeApi.map((item) => ({
      value: item._id,
      label: item.name,
    })),
  ];

  const getPitchNameData = async () => {
    try {

      const response = await PitchNameService.getPitchName(searchText);
      setPitchNameApi(response.data.data);

      const body = response.data.data
        .map((item) => [
          {
            value: item._id,
            label: item.name,
          },
        ])
        .flat();
      let filterValue = body.filter(_value => !selectedValues?.some(obj2 => obj2.value === _value.value))
      setSearchedData(filterValue);
    } catch (error) {
      console.log("GetAll PitchName errrrrr", error);
    }
  };
  useEffect(() => {
    getPitchNameData();
  }, [searchText]);

  const pitchNamesOptions = [
    ...pitchNameApi.map((item) => ({
      value: item._id,
      label: item.name,
    })),
  ];

  const importantChange = (e, index) => {
    const value = e.target.value;
    formik.setFieldValue(`important[${index}]`, value);
  };

  const rulesChange = (e, index) => {
    const value = e.target.value;
    formik.setFieldValue(`rules[${index}]`, value);
  };

  const addNumber = () => {
    if (
      formik.values.important.length === 0 ||
      formik.values.important[0].trim() === ""
    ) {
      return;
    }
    formik.setFieldValue("important", [...formik.values.important, ""]);
    setAddNumbers(addNumbers + 1);
  };

  const removeNumber = (index) => {
    if (addNumbers > 1) {
      formik.setFieldValue(
        "important",
        formik.values.important.filter((_, i) => i !== index)
      );
      setAddNumbers(addNumbers - 1);
    }
  };

  const addRule = () => {
    if (
      formik.values.rules.length === 0 ||
      formik.values.rules[0].trim() === ""
    ) {
      // Show an alert or handle the empty input as needed
      // alert('Please fill the first rule before adding more.');
      return;
    }
    formik.setFieldValue("rules", [...formik.values.rules, ""]);
    setAddRules(addRules + 1);
  };

  const removeRule = (index) => {
    if (addRules > 1) {
      formik.setFieldValue(
        "rules",
        formik.values.rules.filter((_, i) => i !== index)
      );
      setAddRules(addRules - 1);
    }
  };

  const validateForm = () => {
    return (
      formik.values.startTime &&
      formik.values.endTime &&
      formik.values.individualGame.price &&
      formik.values.individualGame.costOfPitch &&
      formik.values.fullPitchBooking.price &&
      formik.values.fullPitchBooking.costOfPitch &&
      formik.values.friendlyGame.price &&
      formik.values.friendlyGame.costOfPitch
    );
  };


  const [searchedData, setSearchedData] = useState([]);
  const [selectedValues, setSelectedValues] = useState(formik.values.pitch);
  const [isEnterMouse, setIsEnterMouse] = useState(false);
  const [isAllowCashPayment, setIsAllowCashPayment] = useState(false);
  const [isAllowOpenInvitation, setIsAllowOpenInvitation] = useState(false);

  const handleChangeName = (e) => {
    setSearchText(e.target.value);
    // Logic to filter the searched data
    // Example: setSearchedData(filteredData);
  };


  const handleSelect = (item) => {
    if (!selectedValues.includes(item)) {
      setSelectedValues([...selectedValues, item]);
      formik.values.pitch = formik.values.pitch[0] === '' ? [] : formik.values.pitch
      formik.values.pitch.push(item)
    }
    setSearchText("");
  };

  const handleRemove = (item) => {
    setSelectedValues(selectedValues.filter((selected) => selected !== item));
    formik.values.pitch = formik.values.pitch.filter((selected) => selected != item)
  };

  const handleCheckboxChange = () => {
    setIsAllowCashPayment(!isAllowCashPayment);
    formik.values.isAllowCashPayment = !isAllowCashPayment
  }
  const handleCheckboxOpenInvitationChange = () => {
    setIsAllowOpenInvitation(!isAllowOpenInvitation);
    formik.values.isAllowOpenInvitation = !isAllowOpenInvitation
  }

  return (
    <Modal
      show={showModal}
      onHide={handleModalClose}
      centered
      size="lg"
      backdrop="static"
    >
      <form onSubmit={formik.handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditMode ? "Edit Game Settings" : "Create Game Settings"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            {/*****************************************************[ Game-Settings Name ]*******************************************************/}

            <Form.Group as={Col} controlId="name">
              <Form.Label style={{ color: "black" }}>
                Game-Settings Name
              </Form.Label>
              <Form.Control
                style={{ height: "37px" }}
                type="text"
                name="name"
                value={formik.values.name}
                placeholder="Enter setting name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.name && formik.errors.name}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.name &&
                  (formik.errors.name || formikErrors.name)}
              </Form.Control.Feedback>
            </Form.Group>

            {/*****************************************************[ Pitch-Type ]*******************************************************/}

            <Row className="mb-3 mt-3">
              <Col sm={4}>
                <Form.Group as={Col} controlId="pitchTypeId">
                  <Form.Label style={{ color: "black", marginLeft: "-10px" }}>
                    Pitch-Type
                  </Form.Label>
                  <Form.Control
                    as="select"
                    name="pitchTypeId"
                    value={
                      formik.values.pitchTypeId
                        ? formik.values.pitchTypeId._id
                        : ""
                    }
                    onChange={handlePitchChange}
                    className="form-select"
                    isInvalid={
                      formik.touched.pitchTypeId && formik.errors.pitchTypeId
                    }
                    style={{
                      marginLeft: "-10px",
                      width: "113%",
                      textAlign: "center",
                      height: "36px",
                      border: "1px solid black",
                    }}
                  >
                    <option value="" style={{ fontWeight: "lighter" }}>
                      Select pitch type
                    </option>
                    {pitchTypesOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </Form.Control>

                  <Form.Control.Feedback type="invalid">
                    {formik.touched.pitchTypeId &&
                      (formik.errors.pitchTypeId || formikErrors.pitchTypeId)}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>

              {/*****************************************************[ Pitch-Name ]*******************************************************/}

              <Col sm={4}>
                <Form.Group as={Col} controlId="pitch">
                  <Form.Label style={{ color: "black" }}>Pitch-Name</Form.Label>
                  {/* <div className="pb-3" style={{ borderRadius: "0px" }}>
                    <input
                      type="text"
                      style={{
                        width: "100%",
                        borderRadius: "8px",
                        outline: "none",
                        border: "1px solid",
                        padding: "5px 10px",
                        height: "36px",
                      }}
                      value={searchText}
                      onChange={handleChangeName}
                      onMouseDown={getPitchNameData}
                    />
                    {!isEnterMouse && searchedData != "" && (
                      <div
                        style={{
                          position: "absolute",
                          maxHeight: "300px",
                          background: "#e9e9e9",
                          width: "90%",
                          // paddingBottom: "10px",
                          // paddingTop: "10px",
                          overflow: "auto",
                          borderRadius: "10px",
                          // borderBottomRightRadius: "10px",
                          // borderBottomLeftRadius: "10px",
                          marginTop: "10px",
                          zIndex: 10,
                        }}
                      >
                        {searchedData.map((item, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                cursor: "pointer",
                                padding: "10px",
                                marginBottom: "4px",
                                marginTop: "4px",
                              }}
                              className="user-list"
                              onClick={() => playerClick(item)}
                            >
                              <p className="m-0">{item.label} </p>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div> */}


                  <div className="pb-3" style={{ position: "relative" }}>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        padding: "5px",
                        cursor: "text",
                      }}
                      onClick={() => document.getElementById("searchInput").focus()}
                    >
                      {formik.values.pitch.filter(data => data !== "").map((item, index) => (
                        <div
                          key={index}
                          style={{
                            display: "inline-block",
                            background: "#007bff",
                            color: "#fff",
                            borderRadius: "5px",
                            padding: "5px 10px",
                            marginRight: "5px",
                            marginBottom: "5px",
                            cursor: "pointer"
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemove(item);
                          }}
                        >
                          {item.label} <IoMdClose />
                        </div>
                      ))}
                      <input
                        id="searchInput"
                        type="text"
                        placeholder={!formik.values.pitch.filter(data => data !== "").length && "Pitch Name"}
                        style={{
                          flex: 1,
                          border: "none",
                          outline: "none",
                          minWidth: "100px",
                          padding: "2px",
                          height: "26px"
                        }}
                        value={searchText}
                        onChange={handleChangeName}
                        onMouseDown={getPitchNameData}
                      />
                    </div>

                    {/* Search results dropdown */}
                    {!isEnterMouse && searchText !== "" && searchedData.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          maxHeight: "300px",
                          background: "#e9e9e9",
                          width: "100%",
                          overflow: "auto",
                          borderRadius: "10px",
                          marginTop: "10px",
                          zIndex: 10,
                        }}
                      >
                        {searchedData
                          .filter((item) => !selectedValues.includes(item))
                          .map((item, index) => (
                            <div
                              key={index}
                              style={{
                                cursor: "pointer",
                                padding: "10px",
                                marginBottom: "4px",
                                marginTop: "4px",
                              }}
                              className="user-list"
                              onClick={() => handleSelect(item)}
                            >
                              <p className="m-0">{item.label} </p>
                            </div>
                          ))}
                      </div>
                    )}
                  </div>

                </Form.Group>
              </Col>

              <Col sm={4}>
                <Form.Group as={Col} controlId="pitch">
                  <Form.Label style={{ color: "black" }}>Game-Type</Form.Label>
                  <Select
                    classNamePrefix="Select2"
                    options={pitchTypes}
                    isMulti
                    name="gameType"
                    onChange={handlePitchTypeChange}
                    value={formik.values.gameType}
                    onBlur={formik.handleBlur}
                    // value={pitchTypes.find(
                    //   (option) => option.value === formik.values.gameType
                    // )}
                    placeholder="Pitch Type"
                    isInvalid={
                      formik.touched.gameType && formik.errors.gameType
                    }
                    style={{ fontWeight: "bold" }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.touched.gameType && formik.errors.gameType}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            {/*****************************************************[ Start Time ]*******************************************************/}

            <Row className="mb-3 mt-3 pe-0">
              <Col className="p-0">
                <Form.Group controlId="startTime">
                  <Form.Label style={{ color: "black" }}>Start Date</Form.Label>
                  <DatePicker
                    style={{
                      width: "100%",
                      textAlign: "center",
                      letterSpacing: "2px",
                      height: "32px",
                      border: "1px solid black",
                    }}
                    format="DD/MM/YYYY"
                    placeholder="Start date"
                    value={formik.values.startDate}
                    minDate={new Date()}
                    onChange={(date) => handleDateChange(date, "start")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.touched.startDate && formik.errors.startDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col className="p-0">
                <Form.Group controlId="startTime">
                  <Form.Label style={{ color: "black" }}>End Date</Form.Label>
                  <DatePicker
                    style={{
                      width: "100%",
                      textAlign: "center",
                      letterSpacing: "2px",
                      height: "32px",
                      border: "1px solid black",
                    }}
                    format="DD/MM/YYYY"
                    value={formik.values.endDate}
                    placeholder="End date"
                    minDate={new Date()}
                    onChange={(date) => handleDateChange(date, "end")}
                  />
                  <Form.Control.Feedback type="invalid">
                    {formik.touched.endDate && formik.errors.endDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>

            <Form.Group as={Col} controlId="startTime">
              <Form.Label style={{ color: "black" }}>Start Time</Form.Label>
              <DatePicker
                placeholder="00:00"
                style={{
                  width: "100%",
                  textAlign: "center",
                  letterSpacing: "2px",
                  height: "32px",
                  border: "1px solid black",
                }}
                onChange={(selectedTime) => {
                  const selectedTimeString = selectedTime.format("HH:mm");
                  formik.setFieldValue("startTime", selectedTimeString);
                  setLocalStartTime(selectedTimeString);
                  setIsStartChange(true);
                }}
                value={
                  localStartTime
                    ? new Date(`2000-01-01T${localStartTime}`)
                    : null
                }
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.startTime && formik.errors.startTime}
                disableDayPicker
                name="startTime"
                format="HH:mm"
                plugins={[<TimePicker />]}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.startTime && formik.errors.startTime}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId="endTime">
              <Form.Label style={{ color: "black" }}>End Time</Form.Label>
              <DatePicker
                placeholder="00:00"
                style={{
                  width: "100%",
                  textAlign: "center",
                  letterSpacing: "2px",
                  height: "32px",
                  border: "1px solid black",
                }}
                onChange={(selectedTime) => {
                  const selectedTimeString = selectedTime.format("HH:mm");
                  formik.setFieldValue("endTime", selectedTimeString);
                  setLocalEndTime(selectedTimeString);
                  setIsEndChange(true);
                }}
                name="endTime"
                // onChange={(selectedTime) => {
                //   const selectedTimeString = selectedTime.format("HH:mm");
                //   const startDateTimeString = localStartTime
                //     ? `2000-01-01T${localStartTime}`
                //     : null;
                //   const selectedDateTimeString = `2000-01-01T${selectedTimeString}`;

                //   if (
                //     startDateTimeString &&
                //     new Date(selectedDateTimeString) <
                //       new Date(startDateTimeString)
                //   ) {
                //     // Set an error message for the formik field
                //     formik.setFieldError(
                //       "endTime",
                //       "End time cannot be earlier than start time"
                //     );
                //     return;
                //   }

                //   formik.setFieldValue("endTime", selectedTimeString);
                //   setLocalEndTime(selectedTimeString);
                //   setIsEndChange(true);
                // }}
                value={
                  localEndTime ? new Date(`2000-01-01T${localEndTime}`) : null
                }
                isInvalid={formik.touched.endTime && formik.errors.endTime}
                disableDayPicker
                format="HH:mm"
                plugins={[<TimePicker />]}
              />
              <Form.Control.Feedback type="invalid">
                {formik.touched.endTime &&
                  (formik.errors.endTime || formikErrors.endTime)}
              </Form.Control.Feedback>
            </Form.Group>

            {/*****************************************************[ Game Days ]*******************************************************/}

            <Form.Group as={Col} controlId="dayType">
              <Form.Label style={{ color: "black" }}>Game Days</Form.Label>
              <Form.Control
                as="select"
                name="dayType"
                className="form-select"
                value={formik.values.dayType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={formik.touched.dayType && formik.errors.dayType}
              >
                <option style={{ fontWeight: "lighter" }} value="">
                  Select days
                </option>
                <option value="WEEKDAYS">WEEKDAYS</option>
                <option value="WEEKENDS">WEEKENDS</option>
              </Form.Control>
              <Form.Control.Feedback type="invalid">
                {formik.touched.dayType &&
                  (formik.errors.dayType || formikErrors.dayType)}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          {/*****************************************************[ Rules of the game ]*******************************************************/}

          <Row className="" style={{ justifyContent: "end" }}>
            <Form.Label style={{ color: "black" }}>
              Rules of the game
            </Form.Label>
            <div className="rules-container">
              {[...Array(addRules)].map((_, index) => (
                <>
                  <Form.Group
                    className="form-group"
                    key={index}
                    style={{ display: "flex" }}
                  >
                    <Form.Control
                      type="text"
                      name={`rules[${index}]`}
                      placeholder={`Enter here rules ${index + 1}`}
                      value={formik.values.rules[index]}
                      isInvalid={formik.touched.rules && formik.errors.rules}
                      onChange={(e) => rulesChange(e, index)}
                      onBlur={formik.handleBlur}
                      className="m-2"
                    />
                    {index === addRules - 1 ? (
                      <>
                        <Button
                          size="small"
                          type="button"
                          className="btn btn-primary btn-sm ms-2"
                          style={{ padding: "0px", borderRadius: "10px" }}
                          onClick={addRule}
                        >
                          Add Rule
                        </Button>
                        {index > 0 && (
                          <Button
                            size="small"
                            type="button"
                            style={{
                              borderRadius: "35px",
                              padding: "5px",
                              height: "30px",
                            }}
                            className="btn btn-sm btn-danger ms-2"
                            onClick={() => removeRule(index)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        size="small"
                        type="button"
                        style={{
                          borderRadius: "35px",
                          padding: "5px",
                          height: "30px",
                        }}
                        className="btn btn-sm btn-danger ms-2"
                        onClick={() => removeRule(index)}
                      >
                        <i className="fa fa-times"></i>
                      </Button>
                    )}
                  </Form.Group>
                  {/* {formik.touched.rules && formik.errors.rules && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        color: "red",
                      }}
                    >
                      {formik.errors.rules}
                    </div>
                  )} */}
                </>
              ))}
            </div>
          </Row>

          {/*****************************************************[ Important of the game ]*******************************************************/}

          <Row className="" style={{ justifyContent: "end" }}>
            <Form.Label style={{ color: "black" }}>
              Important of the game
            </Form.Label>
            <div className="rules-container">
              {[...Array(addNumbers)].map((_, index) => (
                <>
                  <Form.Group
                    className="form-group"
                    key={index}
                    style={{ display: "flex" }}
                  >
                    <Form.Control
                      type="text"
                      name={`important[${index}]`}
                      onChange={(e) => importantChange(e, index)}
                      onBlur={formik.handleBlur}
                      placeholder={`Enter here important ${index + 1}`}
                      value={formik.values.important[index]}
                      isInvalid={
                        formik.touched.important && formik.errors.important
                      }
                      className="m-2"
                    />
                    {index === addNumbers - 1 ? (
                      <>
                        <Button
                          size="small"
                          type="button"
                          style={{ padding: "0px", borderRadius: "10px" }}
                          className="btn btn-primary btn-sm ms-2"
                          onClick={addNumber}
                        >
                          Add Important
                        </Button>
                        {index > 0 && (
                          <Button
                            size="sm"
                            type="button"
                            style={{
                              borderRadius: "35px",
                              padding: "5px",
                              height: "30px",
                            }}
                            className="btn btn-sm btn-danger ms-2"
                            onClick={() => removeNumber(index)}
                          >
                            <i className="fa fa-times"></i>
                          </Button>
                        )}
                      </>
                    ) : (
                      <Button
                        type="button"
                        style={{
                          borderRadius: "35px",
                          padding: "5px",
                          height: "30px",
                        }}
                        className="btn btn-sm btn-danger ms-2"
                        onClick={() => removeNumber(index)}
                      >
                        <i className="fa fa-times"></i>
                      </Button>
                    )}
                  </Form.Group>
                  {formik.touched.important && formik.errors.important && (
                    <div
                      style={{
                        position: "absolute",
                        bottom: "-20px",
                        color: "red",
                      }}
                    >
                      {formik.errors.important}
                    </div>
                  )}
                </>
              ))}
            </div>
          </Row>

          <hr></hr>
          {/*****************************************************[ H R ]*******************************************************/}

          {/*****************************************************[ Individual Games ]*******************************************************/}

          <>
            <p
              style={{
                textAlign: "center",
                color: "black",
                fontWeight: "bold",
              }}
            >
              Individual Games
            </p>

            <Row className="mb-3">
              <Form.Group as={Col} controlId="individualGame.price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="individualGame.price"
                  value={formik.values.individualGame.price}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("individualGame.price", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("individualGame.price", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "individualGame.price",
                        Number(value)
                      );
                    }
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.touched.individualGame &&
                    formik.errors.individualGame?.price}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} controlId="individualGame.targetMargin">
                <Form.Label>Target Margin (%)</Form.Label>
                <Form.Control
                  type="number"
                  name="individualGame.targetMargin"
                  value={formik.values.individualGame.targetMargin}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("individualGame.targetMargin", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("individualGame.targetMargin", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "individualGame.targetMargin",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="individualGame.costOfPitch">
                <Form.Label> Cost of Pitch</Form.Label>
                <Form.Control
                  type="number"
                  name="individualGame.costOfPitch"
                  value={formik.values.individualGame.costOfPitch}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("individualGame.costOfPitch", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("individualGame.costOfPitch", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "individualGame.costOfPitch",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="individualGame.hostCommission">
                <Form.Label>Commission of Pitch(%)</Form.Label>
                <Form.Control
                  type="number"
                  name="individualGame.hostCommission"
                  value={formik.values.individualGame.hostCommission}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("individualGame.hostCommission", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("individualGame.hostCommission", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "individualGame.hostCommission",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>
            </Row>

            {/*****************************************************[ Full Pitch Booking ]*******************************************************/}

            <p
              style={{
                textAlign: "center",
                color: "#00ADEF",
                fontWeight: "bold",
              }}
            >
              Full Pitch Booking
            </p>

            <Row className="mb-3">
              {formik.values?.gameType != "" &&
                formik.values?.gameType?.map((item) =>
                  item?.value === "FULL-PITCH" ? (
                    !formik.values?.isDeposit ? (
                      <Form.Group
                        as={Col}
                        style={{ display: "flex", alignItems: "end" }}
                      >
                        <Form.Check
                          type="checkbox"
                          name="isDeposit"
                          label="Add Price Deposit"
                          onChange={formik.handleChange}
                        />
                      </Form.Group>
                    ) : (
                      <Form.Group as={Col}>
                        <Form.Label>Price Deposit</Form.Label>
                        <Form.Control
                          type="number"
                          name="fullPitchBooking.depositPrice"
                          value={formik.values.fullPitchBooking.depositPrice}
                          onFocus={(e) => {
                            if (e.target.value === "0") {
                              e.target.value = "";
                              formik.setFieldValue(
                                "fullPitchBooking.depositPrice",
                                ""
                              );
                            }
                          }}
                          onBlur={(e) => {
                            if (e.target.value === "") {
                              e.target.value = "0";
                              formik.setFieldValue(
                                "fullPitchBooking.depositPrice",
                                0
                              );
                            }
                          }}
                          onChange={(e) => {
                            const value = e.target.value;
                            if (value >= 0) {
                              formik.setFieldValue(
                                "fullPitchBooking.depositPrice",
                                Number(value)
                              );
                            }
                          }}
                        />
                        <Form.Check
                          type="checkbox"
                          name="isDeposit"
                          label="Remove Price Deposit"
                          onChange={formik.handleChange}
                          checked={formik.values?.isDeposit}
                          className="mt-2"
                        />
                        {console.log(formik.values.isAllowCashPayment, "========== formik.values.isAllowCashPayment =========")}
                        <Form.Check
                          type="checkbox"
                          name="isDeposit"
                          label="Allow payment in cash at the facility"
                          onChange={handleCheckboxChange}
                          checked={formik.values.isAllowCashPayment}
                          className="mt-2"
                        />

                      </Form.Group>
                    )
                  ) : (
                    <></>
                  )
                )}

              <Form.Group as={Col} controlId="fullPitchBooking.price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="fullPitchBooking.price"
                  value={formik.values.fullPitchBooking.price}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("fullPitchBooking.price", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("fullPitchBooking.price", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "fullPitchBooking.price",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="fullPitchBooking.costOfPitch">
                <Form.Label> Cost of Pitch</Form.Label>
                <Form.Control
                  type="number"
                  name="fullPitchBooking.costOfPitch"
                  value={formik.values.fullPitchBooking.costOfPitch}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("fullPitchBooking.costOfPitch", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("fullPitchBooking.costOfPitch", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "fullPitchBooking.costOfPitch",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="fullPitchBooking.profitMargin">
                <Form.Label>Profit Margin(%)</Form.Label>
                <Form.Control
                  type="number"
                  name="fullPitchBooking.profitMargin"
                  // value={formik.values.fullPitchBooking.profitMargin}
                  disabled
                  value={Math.round(((formik.values.fullPitchBooking.price - formik.values.fullPitchBooking.costOfPitch) / formik.values.fullPitchBooking.price) * 100)}

                // onFocus={(e) => {
                //   if (e.target.value === "0") {
                //     e.target.value = "";
                //     formik.setFieldValue("fullPitchBooking.profitMargin", "");
                //   }
                // }}
                // onBlur={(e) => {
                //   if (e.target.value === "") {
                //     e.target.value = "0";
                //     formik.setFieldValue("fullPitchBooking.profitMargin", 0);
                //   }
                // }}
                // onChange={(e) => {
                //   const value = e.target.value;
                //   if (value >= 0) {
                //     formik.setFieldValue(
                //       "fullPitchBooking.profitMargin",
                //       Number(value)
                //     );
                //   }
                // }}
                />
              </Form.Group>
            </Row>

            {/*****************************************************[ Friendly Games ]*******************************************************/}

            <p
              style={{
                textAlign: "center",
                color: "#C4273B",
                fontWeight: "bold",
              }}
            >
              Friendly Games
            </p>
            <Row className="mb-3">
              <Form.Group as={Col} controlId="friendlyGame.price">
                <Form.Label>Price</Form.Label>
                <Form.Control
                  type="number"
                  name="friendlyGame.price"
                  value={formik.values.friendlyGame.price}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("friendlyGame.price", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("friendlyGame.price", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue("friendlyGame.price", Number(value));
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="friendlyGame.costOfPitch">
                <Form.Label> Cost of Pitch</Form.Label>
                <Form.Control
                  type="number"
                  name="friendlyGame.costOfPitch"
                  value={formik.values.friendlyGame.costOfPitch}
                  onFocus={(e) => {
                    if (e.target.value === "0") {
                      e.target.value = "";
                      formik.setFieldValue("friendlyGame.costOfPitch", "");
                    }
                  }}
                  onBlur={(e) => {
                    if (e.target.value === "") {
                      e.target.value = "0";
                      formik.setFieldValue("friendlyGame.costOfPitch", 0);
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      formik.setFieldValue(
                        "friendlyGame.costOfPitch",
                        Number(value)
                      );
                    }
                  }}
                />
              </Form.Group>

              <Form.Group as={Col} controlId="friendlyGame.profitMargin">
                <Form.Label>Profit Margin(%)</Form.Label>
                <Form.Control
                  type="number"
                  name="friendlyGame.profitMargin"
                  value={Math.round(((formik.values.friendlyGame.price - formik.values.friendlyGame.costOfPitch) / formik.values.friendlyGame.price) * 100)}
                  // onFocus={(e) => {
                  //   if (e.target.value === "0") {
                  //     e.target.value = "";
                  //     formik.setFieldValue("friendlyGame.profitMargin", "");
                  //   }
                  // }}
                  // onBlur={(e) => {
                  //   if (e.target.value === "") {
                  //     e.target.value = "0";
                  //     formik.setFieldValue("friendlyGame.profitMargin", 0);
                  //   }
                  // }}
                  // onChange={(e) => {
                  //   const value = e.target.value;
                  //   if (value >= 0) {
                  //     formik.setFieldValue(
                  //       "friendlyGame.profitMargin",
                  //       Number(value)
                  //     );
                  //   }
                  // }}
                  disabled
                />
              </Form.Group>
            </Row>


            {
              formik.values?.gameType.length && formik.values?.gameType?.filter((_data) => _data.value === 'FULL-PITCH').length > 0 ? (
                <>
                  <Row className="mb-3 align-items-end">
                    <Form.Group as={Col} controlId="fullPitchBooking.profitMargin" className="">
                      <Form.Check
                        type="checkbox"
                        name="isDeposit"
                        label="Allow Open Invitation"
                        onChange={handleCheckboxOpenInvitationChange}
                        checked={formik.values.isAllowOpenInvitation}
                        className="d-flex align-items-center"
                      />
                    </Form.Group>

                    {
                      formik.values.isAllowOpenInvitation ? (
                        <Form.Group as={Col} controlId="openInvitationCount">
                          <Form.Label>Set Maximum Number Of Slots</Form.Label>
                          <Form.Control
                            type="number"
                            name="openInvitationCount"
                            value={formik.values.openInvitationCount}
                            max={pitchTypeApi?.filter((_data) => { return _data._id == formik?.values?.pitchTypeId })[0]?.players}
                            onFocus={(e) => {
                              if (e.target.value === "0") {
                                e.target.value = "";
                                formik.setFieldValue("openInvitationCount", "");
                              }
                            }}
                            onBlur={(e) => {
                              if (e.target.value === "") {
                                e.target.value = "0";
                                formik.setFieldValue("openInvitationCount", 0);
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;

                              const maxVale = pitchTypeApi?.filter((_data) => {
                                return isEditMode ? _data?._id == formik?.values?.pitchTypeId._id : _data?._id == formik?.values?.pitchTypeId

                              })

                              if (value >= 0 && value <= maxVale[0]?.players) {
                                formik.setFieldValue("openInvitationCount", Number(value));
                              }
                              if (value > maxVale[0]?.players) {
                                enqueueSnackbar(`You can not set value more then ${maxVale[0].players}`, { variant: "error", anchorOrigin: { horizontal: "right", vertical: "bottom" } })
                              }
                            }}
                          />
                        </Form.Group>
                      ) : ""
                    }
                    {/* {console.log(pitchTypeApi, "=== formik.values.pitchTypeId ===")} */}

                  </Row>
                </>
              ) : null
            }


          </>

          <div
            style={{ textAlign: "center" }}
            className="d-flex justify-content-center align-items-center"
          >
            {loadingData ? (
              <Button
                size="md"
                style={{ borderRadius: "5px", width: "180px" }}
                variant="primary"
                type="submit"
                disabled
                className="d-flex justify-content-center align-items-center"
              >
                <CircularProgress
                  style={{ width: "25px", height: "25px", color: "white" }}
                />
              </Button>
            ) : (
              <Button
                size="md"
                style={{ borderRadius: "5px" }}
                variant="primary"
                type="submit"
                disabled={!validateForm()}
              >
                {isEditMode ? "Update Game Settings" : "Create Game Settings"}
              </Button>
            )}
          </div>
        </Modal.Body>
      </form>
    </Modal>
  );
};

export default GameSettingEditModal;
