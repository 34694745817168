import serverCall from "../serverCall";

//GetWalletListData
const GetWalletListData = async (id) => {
  try {
    const response = await serverCall.get(
      `/game/admin/weekly-revenue?facilityId=${id}`
    );
    return response;
  } catch (error) {
    console.log("GetWalletListData Api error", error);
  }
};

//Add wallet
const GetWalletAddMoney = async (userId, data) => {
  try {
    const response = await serverCall.post(
      `/admin/add-amount/wallet/${userId}`,
      data
    );
    return response;
  } catch (error) {
    console.log("DeleteCityData Api error", error);
  }
};

//UpdateWallet

const UpdateWallet = async (id, name) => {
  try {
    const response = await serverCall.post(
      `/admin/update-amount/wallet/${id}`,
      name
    );
    return response;
  } catch (error) {
    console.log("UpdateCityData Api error", error);
  }
};

//GetUpdateWalletSingleId
const GetUpdateWalletSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/admin/user/${id}`);
    return response;
  } catch (error) {
    console.log("UserTransactionList SingleId Api error", error);
  }
};

const WalletData = {
  GetWalletListData,
  UpdateWallet,
  GetUpdateWalletSingleId,
  GetWalletAddMoney,
};

export default WalletData;
