import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const PitchNameEditModal = (props) => {
  const {
    show,
    handleClose,
    handleSave,
    isEdit,
    facilitiseSelect,
    facilitise,
    setFacilitise,
    formik,
    formikErrors,
  } = props;

  const [pitchNames, setPitchNames] = useState("");

  useEffect(() => {
    if (isEdit && facilitiseSelect) {
      setPitchNames(facilitiseSelect.name);
    } else {
      setPitchNames("");
    }
    formik.resetForm();
  }, [show, isEdit, facilitiseSelect]);

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Pitch Name" : "Create Pitch Name"}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div style={{ justifyContent: "center" }}>
            <Form.Group controlId="name" style={{}}>
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              />
              {!isEdit && formik.touched.name && formikErrors.name && (
                <div className="error-message text-danger">
                  {formikErrors.name}
                </div>
              )}
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="primary"
            type="submit"
            style={{ borderRadius: "10px" }}
          >
            {isEdit ? "Update Pitch-Name" : "Add Pitch-Name"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default PitchNameEditModal;
