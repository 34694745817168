import serverCall from "../serverCall";

//getBankType
const getBankType = async () => {
  try {
    const response = await serverCall.get(`/bank-account/admin/getall`);

    return response;
  } catch (error) {
    console.log("getBankType error", error);
  }
};

//CreateBankType
const CreateBankType = async (name) => {
  try {
    const response = await serverCall.post(`/bank-account/admin`, name);
    return response;
  } catch (error) {
    console.log("getBankType error", error);
  }
};

//GetBankTypeSingleId
const GetBankTypeSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/bank-account/admin/${id}`);
    return response;
  } catch (error) {
    console.log("GetBankTypeSingleId Api error", error);
  }
};

//DeleteBankType
const DeleteBankType = async (id) => {
  try {
    const response = await serverCall.delete(`/bank-account/admin/${id}`);
    return response;
  } catch (error) {
    console.log("DeletePitchType Api error", error);
  }
};

//UpdateBankType
const UpdateBankType = async (id, name) => {
  try {
    const response = await serverCall.put(`/bank-account/admin/${id}`, name);
    return response;
  } catch (error) {
    console.log("UpdateCityData Api error", error);
  }
};

const BankAccountService = {
  getBankType,
  CreateBankType,
  GetBankTypeSingleId,
  DeleteBankType,
  UpdateBankType,
};

export default BankAccountService;
