import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import DataTable from "react-data-table-component";
import TerminateModal from "./TerminateModal";
import TeamEditModal from "./TeamEditModal";
import { enqueueSnackbar } from "notistack";
import TeamData from "../../../../../services/team.service";
import { useFormik } from "formik";
import * as Yup from "yup";

const Team = () => {
  const pageNumner = localStorage.getItem("page");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainData, setMainData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [scroll, setScroll] = useState(true);
  const [selectedTeamName, setSelectedTeamName] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [totalCount, setTotalCount] = useState(0);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  //GetMain CityData Function
  const getMainTeamData = async () => {
    try {
      setLoading(true);
      const response = await TeamData.getTeamData(pageNumber, dataPerPage);
      setMainData(response.data["data"]);
      setTotalCount(response.data["total"]); // Assuming your API returns the total count of records
      // Rest of your code remains the same
    } catch (error) {
      console.log("Error fetching data:", error);
    } finally {
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getMainTeamData();
    localStorage.setItem("page", pageNumber);
  }, [pageNumber, dataPerPage]);


  const handleEditClick = async (id) => {

    setIsEdit(true);
    setShowEditModal(true);
    setSelectedTeam(id);
    if (id) {
      const response = await TeamData.GetTeamSingleId(id);
      const result = response.data.data.team;

      formik.setFieldValue("name", result.name);
      formik.setFieldValue("_id", result._id);
    } else {
      formik.setFieldValue("name", "");
      formik.setFieldValue("_id", "");
      formik.resetForm();
    }
  };

  const handleFormSubmit = async (values, action) => {
    try {
      const response = await TeamData.UpdateTeamRequest(
        values._id,
        values.name
      );
      action.resetForm();
      getMainTeamData();
      setShowEditModal(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 5000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      enqueueSnackbar(e.response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
      console.log("e", e);
    }
  };


  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Team name is required"),
    }),
    onSubmit: handleFormSubmit,
  });

  const columns = [
    {
      name: "ID",
      selector: (row) => row._id,
      sortable: true,
    },

    {
      name: "Created Date",
      selector: (row) => {
        const date = new Date(row.createdAt);
        return date.toLocaleDateString("en-GB");
      },
      sortable: true,
    },
    {
      name: "Team Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Captain Name",
      selector: (row) => {
        if (row?.userId?.firstName && row?.userId?.lastName) {
          return row.userId.firstName + " " + row.userId.lastName;
        } else {
          return (
            <div style={{ textAlign: "center", justifyContent: "center" }}>
              <p style={{ textAlign: "center", justifyContent: "center" }}>
                {" "}
                -{" "}
              </p>
            </div>
          );
        }
      },
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
          }}
        >
          <Button
            style={{ margin: "5px" }}
            size="sm"
            variant="primary"
            onClick={() => handleEditClick(row._id)}
          >
            <i className="fe fe-edit"></i>
          </Button>
          <Button
            style={{ margin: "5px" }}
            size="sm"
            variant="danger"
            onClick={() => handleDeleteClick(row?._id, row?.name)}
          >
            <i className="fa fa-trash" aria-hidden="true"></i>
          </Button>
        </div>
      ),
    },
  ];

  const handleDeleteClick = (_id, name) => {
    setShowDeleteModal(true);
    setSelectedTeamName({ id: _id, name: name });
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await TeamData.DeleteTeamDataRequest(
        selectedTeamName.id
      );

      setShowDeleteModal(false);

      getMainTeamData();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleClose = () => {
    setShowDeleteModal(false);
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    setSelectedTeam(null);
    setIsEdit(false);
  };

  const handleSave = (updatedTeamName) => {
    handleCloseEdit();
  };

  const filteredData = mainData.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(item.createdAt)
        .toLocaleDateString("en-GB")
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      new Date(item.updatedAt)
        .toLocaleDateString("en-GB")
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      (item.userId &&
        (
          item.userId.firstName.toLowerCase() +
          " " +
          item.userId.lastName.toLowerCase()
        ).includes(searchQuery.toLowerCase()))
    );
  });

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const dataToShow = searchQuery ? filteredData : mainData;

  const handlePerPageChange = async (newPerPage, newPageNumber) => {
    setLoading(true);
    setDataPerPage(newPerPage);
    setPageNumber(1); // Reset to first page when changing data per page
    await getMainTeamData();
  };

  const handlePageChange = async (newPageNumber) => {
    setLoading(true);
    setPageNumber(newPageNumber);
    await getMainTeamData();
  };
  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Teams</h2>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-4">
          <Card>
            <Card.Body style={{ padding: "17px" }}>
              <div className="d-flex mb-3" style={{ float: "right" }}>
                <input
                  type="text"
                  placeholder="Search Team..."
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control"
                  style={{ marginRight: "10px", width: "100%" }}
                />
                <Button
                  size="sm"
                  variant="primary"
                  onClick={() => setSearchQuery("")}
                  style={{ borderRadius: "5px" }}
                >
                  Clear
                </Button>
              </div>
              <div className="table-responsive">
                <DataTable
                  columns={columns}
                  data={dataToShow}
                  pagination
                  paginationServer
                  paginationTotalRows={totalCount}
                  paginationRowsPerPageOptions={[5, 10, 50, 100]}
                  paginationPerPage={dataPerPage}
                  paginationDefaultPage={pageNumber}
                  progressPending={loading}
                  onChangeRowsPerPage={(page, prevPage) => {
                    handlePerPageChange(page, prevPage);
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page);
                  }}
                />
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
      <TerminateModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        userId={selectedUserId}
        confirmDelete={handleConfirmDelete}
        teamName={selectedTeamName ? selectedTeamName.name : ""}
      />
      <TeamEditModal
        show={showEditModal}
        handleClose={handleCloseEdit}
        handleSave={handleSave}
        isEdit={isEdit}
        formik={formik}
      />
    </>
  );
};

export default Team;
