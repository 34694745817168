import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Pagination,
  Row,
  Spinner,
} from "react-bootstrap";
import CommunityEditModal from "./CommunityEditModal"; // Assuming the modal file path
import CommunityDeleteModal from "./CommunityDeleteModal";
import CommunityData from "../../../../../services/community.service";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";

const schema = yup.object().shape({
  title: yup
    .string()
    .min(5, "Enter title at least 5 words.!")
    .required("Please enter Title"),
  description: yup
    .string()
    .min(5, "Enter description at least 5 words.!")
    .required("Please enter Description"),
  file: yup.mixed().required("Please select an image "),
});

const Community = () => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [totalPage, setTotalPage] = useState("");
  const pageSize = Math.ceil(totalPage / 6);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedCommunity, setSelectedCommunity] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [editLoader, setEditLoader] = useState(false);

  const getCommunityData = async () => {
    try {
      const response = await CommunityData.GetAllCommunityData(currentPage);
      setData(response.data["data"]);
      // enqueueSnackbar("Get Community successfully", {
      //     variant: "success",
      //     anchorOrigin: {
      //       vertical: "bottom",
      //       horizontal: "right"
      //     }
      //   })
      setLoading(false);
      setTotalPage(response.data.totalCount);
    } catch (error) {
      console.error("Error fetching data", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getCommunityData();
  }, [currentPage, page]);

  const handleShow = async (id) => {
    setSelectedCommunity(id);
    setIsEditMode(true);
    setShowModal(true);
    if (id) {
      setIsEditMode(true);
      setShowModal(true);
      const response = await CommunityData.GetCommunityDataSingleId(id);
      const result = response.data.data;
      formik.setFieldValue("id", result._id);
      formik.setFieldValue("title", result.title);
      formik.setFieldValue("description", result.description);
      formik.setFieldValue("file", result.image);
    } else {
      formik.setFieldValue("title", "");
      formik.setFieldValue("description", "");
      formik.setFieldValue("file", "");
    }
  };

  const previousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const nextPage = (pageNumber) => {
    if (pageNumber <= pageSize) {
      setCurrentPage(pageNumber);
      setPage(pageNumber);
    }
  };

  const handleClose = () => {
    setIsEditMode(false);
    setShowModal(false);
    setSelectedCommunity(null);
  };

  // Function to format date
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();

    // Check if the date is today
    if (date.toDateString() === today.toDateString()) {
      const hours = date.getHours();
      const minutes = date.getMinutes().toString().padStart(2, "0");
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      return `Today ${formattedHours}:${minutes} ${ampm}`;
    } else {
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "long" });
      const year = date.getFullYear();
      return `${day} ${month},${year}`;
    }
  };

  const handleDelete = (_id, title) => {
    setShowDeleteModal(true);
    setSelectedRow(_id);
    setSelectedCommunity(title);
  };

  const confirmDelete = async (id) => {
    try {
      const response = await CommunityData.DeleteCommunityData(id);
      setShowDeleteModal(false);
      getCommunityData();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const submit = async (values, action) => {
    const formData = new FormData();
    formData.append("title", values.title);
    formData.append("description", values.description);
    formData.append("file", values.file);
    if (!values.id) {
      try {
        const response = await CommunityData.CreateCommunityData(formData);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Add Game Community Successfully!", {
            variant: "success",
          });
          formik.resetForm();
          getCommunityData();
          handleClose();
          action.resetForm();
        } else {
          enqueueSnackbar("User already exists with this email", {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar("User already exists with this email", {
          variant: "error",
        });
        console.log("Add Game Community", error);
      }
    } else {
      try {
        const formData = new FormData();
        formData.append("title", values.title);
        formData.append("description", values.description);
        formData.append("file", values.file);

        const response = await CommunityData.UpdateCommunityData(
          values.id,
          formData
        );
        action.resetForm();
        getCommunityData();
        setShowModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      title: selectedCommunity ? selectedCommunity.title : "",
      description: selectedCommunity ? selectedCommunity.description : "",
      file: "",
    },
    onSubmit: submit,
    validationSchema: schema,
  });

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Community</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              style={{ borderRadius: "5px" }}
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => setShowModal(true)}
            >
              <i className="fe fe-plus me-2"></i>Create Community
            </Button>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader-container">
          <h3
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              fontWeight: "bold",
            }}
            className="loader"
          >
            Loading...
          </h3>
        </div>
      ) : data.length === 0 ? (
        <div className="text-center my-4">
          <p>
            No Community items available,if want added click "Create Game
            Community"
          </p>
        </div>
      ) : (
        <Row className="justify-content-center p-2">
          {data.map((game, index) => (
            <GameCard
              key={index}
              game={game}
              handleShow={handleShow}
              handleDelete={handleDelete}
              formatDate={formatDate}
            />
          ))}

          <Pagination
            className="pagination-circle mb-0 ms-lg-3"
            style={{ justifyContent: "center", position: "relative" }}
          >
            <Pagination.Item
              onClick={() => previousPage()} // Handle previous page click
              disabled={currentPage === 1} // Disable if current page is first
            >
              <i className="fas fa-angle-double-left"></i>
            </Pagination.Item>

            {Array.from({ length: Math.ceil(totalPage / 6) }).map(
              (_, index) => {
                const pageNumber = index + 1;
                return (
                  <Pagination.Item
                    onClick={() => nextPage(pageNumber)}
                    key={pageNumber}
                    active={pageNumber === currentPage}
                  >
                    {pageNumber}
                  </Pagination.Item>
                );
              }
            )}

            <Pagination.Item
              onClick={() => nextPage(currentPage + 1)}
              disabled={currentPage === pageSize}
            >
              <i className="fas fa-angle-double-right"></i>
            </Pagination.Item>
          </Pagination>
        </Row>
      )}

      <CommunityEditModal
        showModal={showModal}
        handleClose={() => setShowModal(false)}
        handleSave={formik.handleSubmit}
        loading={editLoader}
        selectedCommunity={selectedCommunity}
        isEditMode={isEditMode}
        formik={formik}
        setIsEditMode={setIsEditMode}
      />

      <CommunityDeleteModal
        showDeleteModal={showDeleteModal}
        setShowDeleteModal={setShowDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
        confirmDelete={confirmDelete}
        _id={selectedRow}
        communityTitle={setSelectedCommunity}
      />
    </>
  );
};

function GameCard({ game, handleShow, handleDelete, formatDate }) {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const handleImageLoaded = () => {
    setImageLoading(false);
  };

  const handleImageError = () => {
    setImageLoading(false);
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  return (
    <Card
      style={{
        width: "100%",
        height: "180px",
        marginTop: "5px",
        marginBottom: "20px",
        borderRadius: "10px",
        border: "1px solid #ccc",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Row style={{ overflowX: "hidden" }}>
        <Col
          key={game.id}
          md="2"
          style={{ padding: "15px" }}
          className="d-flex justify-content-center align-items-center"
        >
          {imageLoading && (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          )}
          <Card.Img
            src={game.image}
            style={{
              borderRadius: "10px",
              height: "150px",
              width: "100%",
              display: imageLoading ? "none" : "block",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.5)",
            }}
            onLoad={handleImageLoaded}
            onError={handleImageError}
          />
        </Col>
        <Col md="10" style={{}}>
          <Card.Body style={{ marginLeft: "-20px", justifyContent: "center" }}>
            <div style={{ display: "flex" }}>
              <Card.Text style={{ marginBottom: "5px", fontWeight: "bolder" }}>
                {game.title}
              </Card.Text>
              <Button
                size="sm"
                style={{
                  float: "right",
                  marginLeft: "auto",
                  marginTop: "-5px",
                  marginBottom: "auto",
                  borderRadius: "15px",
                }}
                type="button"
                onClick={() => handleShow(game._id)}
              >
                <i className="fa fa-pencil"></i>
              </Button>
              <Button
                variant="danger"
                style={{
                  marginTop: "-5px",
                  marginBottom: "auto",
                  marginLeft: "5px",
                  borderRadius: "15px",
                }}
                onClick={() => handleDelete(game._id, game.title)}
                className="btn btn-danger btn-sm"
              >
                <i className="fa fa-trash" />
              </Button>
            </div>

            <Card.Text
              style={{
                overflow: "hidden",
                whiteSpace: "pre-wrap",
                fontSize: "15px",
                maxHeight: showFullDescription ? "none" : "65px",
              }}
            >
              {game.description}
            </Card.Text>
            <Card.Text>
              <small className="text-muted">
                Last updated{" "}
                <b style={{ fontWeight: "400" }}>
                  {formatDate(game.updatedAt)}
                </b>{" "}
                ago
              </small>
              <span
                style={{
                  cursor: "pointer",
                  marginLeft: "5px",
                  color: "blue",
                  fontSize: "11px",
                }}
                onClick={toggleDescription}
              >
                {showFullDescription ? "View Less" : "View More"}
              </span>
            </Card.Text>
          </Card.Body>
        </Col>
      </Row>
    </Card>
  );
}

export default Community;
