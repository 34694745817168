import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import Select from "react-select";

const GameFacilityDetail = () => {
  const { id } = useParams();
  const location = useLocation();
  const { state } = location;
  const [pitchNames, setPitchNames] = useState([{ label: "", value: "" }]);
  const [scroll, setScroll] = useState(true);

  const date = state && state?.date ? state?.date : "unknown";
  const TimeRange = state && state?.TimeRange ? state?.TimeRange : "unknown";
  const Address = state && state?.Address ? state?.Address : "unknown";
  const rules = state && state?.Rules ? state?.Rules : [];
  const important = state && state?.Important ? state?.Important : [];
  const Hostby = state && state?.Hostby ? state?.Hostby : [];
  const PitchType = state && state.PitchType ? state?.PitchType : "unknown";
  const Status = state && state?.Status ? state?.Status : "unknown";

  const statusColor = () => {
    switch (Status) {
      case "Advertised":
        return "green";
      case "Game Goes Ahead":
        return "#00ADEF";
      case "Game is not Advertised":
        return "grey";
      case "Game Cancelled":
        return "red";
      case "No Game Created":
        return "orange";
      case "Pre-Booked Game":
        return "#f945e4";
      default:
        return "black";
    }
  };

  // useEffect(() => {
  //   if (state && state.PitchName) {
  //     setPitchNames(state.PitchName.map(pitch => ({ label: pitch, value: pitch })));
  //   }
  // }, [state]);

  useEffect(() => {
    // Scroll to the top of the page when the modal opens for the first time
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handlePitchNameChange = (selectedOptions, rowIndex) => {
    const updatedPitchNames = [...pitchNames];
    updatedPitchNames[rowIndex] = {
      label: selectedOptions.label,
      value: selectedOptions.value,
    };
    setPitchNames(updatedPitchNames);
  };

  const players = ["Player 1", "Player 2", "Player 3", "Player 4", "Player 5"];
  const PlayerList = [
    "Amit Jain",
    "Vaibhav Shah",
    "Piyush Bansal",
    "Rutvik Sharma",
    "Rishabh Singh",
  ];

  return (
    <>
      <div className="page-header">
        <Card
          style={{
            backgroundColor: "white",
            border: "6px solid #00ADEF",
            textAlign: "center",
            width: "20%",
          }}
          className="p-3"
        >
          <h2
            className="main-content-title tx-24 mg-b-5 mb-2 mt-0"
            style={{ color: "black" }}
          >
            {new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <h5 className="mb-0" style={{ color: "black" }}>
            {TimeRange}
          </h5>
        </Card>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              onClick={() => alert("Game Cancelled")}
              variant="primary"
              type="button"
              style={{
                backgroundColor: "#000000",
                fontSize: "17px",
                fontWeight: "bold",
                padding: "10px",
                borderRadius: "10px",
              }}
            >
              <i className="fa fa-times m-1" aria-hidden="true"></i>Cancel Game
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="d-flex">
            <div className="col-lg-6 mb-3" style={{ display: "flex" }}>
              <Card style={{ width: "100%" }}>
                <div className="col-lg-12 mb-3">
                  <Card.Header style={{ display: "flex" }}>
                    <h3>Details :</h3>
                  </Card.Header>
                  <div className="d-flex">
                    <div className="col-lg-6 mb-3">
                      <Card.Body>
                        <h5>Host Name</h5>
                        <p>{Hostby}</p>

                        <h5>Pitch Type</h5>
                        <p>{PitchType}</p>

                        <h5>Pitch Name</h5>
                        <p>
                          {pitchNames.map((pitch, index) => (
                            <span key={index}>
                              {pitch.label}{" "}
                              {index !== pitchNames.length - 1 ? ", " : ""}
                            </span>
                          ))}
                        </p>
                      </Card.Body>
                    </div>

                    <hr
                      style={{
                        width: "1px",
                        height: "200px",
                        borderLeft: "3px solid black",
                        margin: "17px 1px",
                      }}
                    />
                    <div className="col-lg-6 mb-3">
                      <Card.Body>
                        <h5>Location</h5>
                        <p>{Address}</p>

                        <h5>Status</h5>
                        <p
                          style={{
                            backgroundColor: statusColor(),
                            color: "white",
                            textAlign: "center",
                            padding: "4px",
                            fontSize: "12px",
                            borderRadius: "5px",
                            width: "fit-content",
                          }}
                        >
                          {Status}
                        </p>
                      </Card.Body>
                    </div>
                  </div>
                </div>
              </Card>
            </div>
            <div className="col-lg-6 mb-3" style={{ display: "flex" }}>
              <Card style={{ width: "100%" }}>
                <Card.Header>
                  <h3>Location :</h3>{" "}
                </Card.Header>
                <Card.Body>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14877.966210690114!2d72.83973144999999!3d21.2123479!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1707804531760!5m2!1sen!2sin"
                    style={{ border: "0", width: "100%", height: "100%" }}
                    allowFullScreen={true}
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  />
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div className="d-flex">
            <div className="col-lg-6 mb-3" style={{ display: "flex" }}>
              <Card style={{ width: "100%" }}>
                <Card.Header>
                  <h3>Rules Of The Game :</h3>
                </Card.Header>
                <Card.Body>
                  {rules.map((rule, index) => (
                    <div key={index}>
                      <p>
                        <strong>{`Rule ${index + 1}:`}</strong>{" "}
                        <p style={{ fontSize: "16px" }}>- {rule}</p>
                      </p>
                    </div>
                  ))}
                </Card.Body>
                <Card.Header>
                  <h3>Importants Game :</h3>
                </Card.Header>
                <Card.Body>
                  {/* {important.map((imp, index) => (
                    <div key={index}>
                      <p><strong>{`Important ${index + 1}:`}</strong> <p style={{ fontSize: "16px" }}>- {imp}</p></p>
                    </div>
                  ))} */}
                </Card.Body>
              </Card>
            </div>

            <div className="col-lg-6 mb-3">
              <Card>
                <Card.Header>
                  <h3>List Of Players</h3>
                </Card.Header>
                <Card.Body>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Players</th>
                        <th>Name</th>
                        <th>Rating</th>
                      </tr>
                    </thead>
                    <tbody>
                      {players.map((player, index) => (
                        <tr key={index}>
                          <td>
                            <p className="small text-muted"> {player}</p>
                          </td>
                          <td>
                            {" "}
                            <div className="pb-0">
                              <div className="d-flex justify-content-between">
                                <div>
                                  <p>
                                    <a href="#!" className="text-dark">
                                      {PlayerList[index]}
                                    </a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex flex-row justify-content-start mt-1 mb-4 text-danger">
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                              <FontAwesomeIcon icon={faStar} />
                            </div>
                            <p className="small text-muted">Rated 4.0/5</p>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GameFacilityDetail;
