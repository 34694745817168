import moment from "moment";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Form,
  Modal,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import UserListData from "../../../../../services/userList.service";
import { enqueueSnackbar } from "notistack";
import defaultImage from "../../../../../assets/img/users/7309693.jpg";
import DeleteTarnsactionModal from "./DeleteTransaction";
import WalletData from "../../../../../services/wallet.service";

const UserTypeViewModal = () => {
  const [amount, setAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedCityIndex, setSelectedCityIndex] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [isEditMode, setIsEditMode] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const SubId = state && state?.SubId ? state?.SubId : null;

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTransactions.slice(
    indexOfFirstItem,
    indexOfLastItem
  );
  const totalPages = Math.ceil(filteredTransactions.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const getUserListIdData = async () => {
    try {
      setLoading(true);
      const response = await UserListData.GetUserListSingleId(SubId);
      setData(response.data["data"] || []);

      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getUserListIdData();
  }, []);

  const getUserTransactionListIdData = async () => {
    try {
      setLoading(true);
      const response = await UserListData.GetUserTransactionSingleId(SubId);

      setTransactions(response.data["data"]);
      enqueueSnackbar("Get User Detail successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });

      setLoading(false);
      setLoadingData(false);
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getUserTransactionListIdData();
  }, []);

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handleAddAmount = () => {
    setShowModal(true);
    setIsEditMode(false);
  };

  const handleEditAmount = async (id) => {
    setSelectedCityIndex(id);
    setShowModal(true);
    setIsEditMode(true);
    if (id) {
      try {
        const response = await WalletData.GetUpdateWalletSingleId(id);
        const result = response.data.data;
        setAmount(result.wallet_balance.toString());
      } catch (error) {
        console.log("Error fetching amount for edit:", error);
      }
    } else {
      setAmount("");
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setAmount("");
    // setEditAmount("");
  };

  useEffect(() => {
    setFilteredTransactions(transactions);
  }, [transactions]);

  const handleSearch = () => {
    const filtered = transactions.filter(
      (transaction) =>
        transaction._id.toLowerCase().includes(searchQuery.toLowerCase()) ||
        moment(transaction.transactionDate)
          .format("DD-MM-YYYY")
          .includes(searchQuery.toLowerCase()) ||
        transaction.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.amount.toString().includes(searchQuery.toLowerCase()) ||
        transaction.txntype.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredTransactions(filtered);
    setCurrentPage(1); // Reset to first page after search
  };

  const clearSearch = () => {
    setSearchQuery("");
    setFilteredTransactions(transactions);
    setCurrentPage(1); // Reset to first page after clearing search
  };

  const handleAddAmountSubmit = async (SubId) => {
    try {
      setLoading(true);
      if (isEditMode) {
        const json = {
          amount: parseFloat(amount),
        };
        const response = await WalletData.UpdateWallet(SubId, json);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        getUserTransactionListIdData();
        setLoading(false);
        setShowModal(false);
        handleEditAmount();
        getUserListIdData();
        getUserTransactionListIdData();
      } else {
        const json = {
          amount: parseFloat(amount),
        };
        const response = await WalletData.GetWalletAddMoney(SubId, json);
        setAmount(amount);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        getUserListIdData();
        getUserTransactionListIdData();
      }
      setAmount("");
      setLoading(false);
      setShowModal(false);
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
      enqueueSnackbar(error.response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleAddAmountSubmit();
    }
  };

  return (
    <>
      <div
        className="page-header"
        style={{ marginBottom: "0px", marginTop: "10px" }}
      >
        <button
          size="sm"
          className="btn btn-primary"
          style={{ borderRadius: "20px", margin: "0px" }}
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">User Details</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              size="md"
              onClick={handleAddAmount}
              variant="primary"
              type="button"
              style={{ fontWeight: "bold", borderRadius: "10px" }}
            >
              <i className="fa fa-plus m-1" aria-hidden="true"></i>Add Amount
            </Button>
          </div>
        </div>
      </div>

      {/* User Profile : */}

      <div className="row">
        <div className="col-lg-12 mb-3">
          <div style={{ display: "flex", flexWrap: "wrap" }}>
            <div className="col-lg-6 col-sm-12 mb-3">
              <Card style={{ overflow: "hidden" }}>
                <Card.Header style={{ fontSize: "25px", fontWeight: "bold" }}>
                  User Profile :
                </Card.Header>
                <Card.Body
                  style={{
                    fontSize: "17px",
                    WebkitUserModify: "read-write-plaintext-only",
                    overflow: "auto",
                  }}
                >
                  <div className="d-flex align-items-center justify-content-around flex-wrap">
                    <div
                      className="profile-image-container"
                      contentEditable={false}
                      style={{
                        pointerEvents: "none",
                        boxShadow: "0px 14px 25px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      <img
                        src={data.profileImage || defaultImage}
                        alt="Profile"
                        className="img-fluid rounded"
                        style={{ height: "100%", width: "100%" }}
                      />
                    </div>
                    <div style={{ marginTop: "20px" }} contentEditable={false}>
                      <strong>User Created : </strong>{" "}
                      {data.createdAt
                        ? moment(data.createdAt).format("DD-MM-YYYY")
                        : " - "}
                      <br />
                      <strong>User Name : </strong>
                      {data.firstName} {data.lastName} <br />
                      <strong>User Type : </strong>{" "}
                      <b style={{ color: "green" }}>
                        {data.roles ? data.roles : " - "}
                      </b>
                      <br />
                      <strong>User Nationality : </strong>{" "}
                      {data.nationality ? data.nationality : " - "}
                      <br />
                      <strong>Birth Date : </strong>
                      {data.dob ? moment(data.dob).format("DD-MM-YYYY") : " - "}
                      <br />
                      <strong>Game Played : </strong>{" "}
                      {data.gamePlayed ? data.gamePlayed : " - "}
                      <br />
                      <strong>E-mail : </strong>
                      {data.email} {data.emailverified ? "✅" : "❌"}
                      <br />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>

            {/* User Wallet Balance: */}

            <div className="col-lg-3 col-sm-12 mb-3">
              <Card style={{ overflow: "hidden" }}>
                <Card.Header style={{ fontSize: "25px", fontWeight: "bold" }}>
                  User Wallet Balance:
                </Card.Header>
                <Card.Body style={{ fontSize: "27px", textAlign: "center" }}>
                  <strong style={{ color: "darkgreen" }}>
                    {data.wallet_balance} <span>&#8364;</span>
                  </strong>
                  <br />
                  <Button
                    size="sm"
                    onClick={() => handleEditAmount(data._id)}
                    variant="warning  "
                    type="button"
                    style={{
                      fontWeight: "bold",
                      borderRadius: "10px",
                      marginBottom: "10px",
                      marginLeft: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <i className="fa fa-edit m-1" aria-hidden="true"></i>Edit
                    Amount
                  </Button>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>

        {/* User Transaction History : */}

        <div className="col-lg-12 mb-3">
          <Card style={{ overflow: "hidden" }}>
            <Card.Header className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h4 style={{ color: "#615f78" }}>User Transaction History :</h4>
              <div className="d-flex align-items-center mt-3 mt-sm-0">
                <input
                  type="text"
                  placeholder="Search Transaction..."
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    handleSearch();
                  }}
                  className="form-control"
                  style={{ marginRight: "10px" }}
                />
                <Button
                  size="sm"
                  style={{
                    borderRadius: "5px",
                    fontSize: "12px",
                    padding: "6px",
                  }}
                  variant="primary"
                  onClick={clearSearch}
                >
                  Clear
                </Button>
              </div>
            </Card.Header>
            <Card.Body style={{ overflow: "auto" }}>
              <div>
                {loading ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                ) : (
                  <Table striped bordered hover style={{ textAlign: "center" }}>
                    <thead
                      style={{ backgroundColor: "#0DB2F0", color: "black" }}
                    >
                      <tr>
                        <th style={{ fontWeight: "bold" }}>Id</th>
                        <th style={{ fontWeight: "bold" }}>Date</th>
                        <th style={{ fontWeight: "bold" }}>Status</th>
                        <th style={{ fontWeight: "bold" }}>Amount</th>
                        <th style={{ fontWeight: "bold" }}>Transaction</th>
                      </tr>
                    </thead>

                    <tbody>
                      {currentItems.length > 0 ? (
                        currentItems.map((transaction) => (
                          <tr key={transaction.id}>
                            <td>{transaction._id}</td>
                            <td>
                              {moment(transaction.transactionDate).format(
                                "DD-MM-YYYY"
                              )}
                            </td>
                            <td>{transaction.type}</td>
                            <td style={{ textAlign: "center" }}>
                              {transaction.txntype === "DR" ? "-" : "+"}{" "}
                              {transaction.amount} <span>&#8364;</span>
                            </td>
                            <td
                              style={{
                                fontWeight: "bold",
                                textAlign: "center",
                                color:
                                  transaction.txntype === "DR"
                                    ? "red"
                                    : transaction.txntype === "CR"
                                    ? "green"
                                    : "black",
                              }}
                            >
                              {transaction.txntype}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" style={{ textAlign: "center" }}>
                            No transactions available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                )}
              </div>
              {/* Pagination */}
              <div className="d-flex justify-content-center mt-4">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (number) => (
                      <Pagination.Item
                        key={number}
                        active={currentPage === number}
                        onClick={() => paginate(number)}
                      >
                        {number}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* Modal Add Amount : */}

      <Modal
        size="sm"
        show={showModal}
        onHide={handleCloseModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{isEditMode ? "Edit Amount" : "Add Amount"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="amount">
              <Form.Label>Amount</Form.Label>
              <Form.Control
                style={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
                type="number"
                placeholder="Enter amount"
                value={amount}
                min={0}
                onChange={(e) => setAmount(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseModal}
            style={{ borderRadius: "10px" }}
          >
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => handleAddAmountSubmit(SubId)}
            style={{ borderRadius: "10px" }}
          >
            {isEditMode ? "Update Amount" : "Add Amount"}
          </Button>
        </Modal.Footer>
      </Modal>
      <DeleteTarnsactionModal
        show={showDeleteModal}
        handleClose={() => setShowDeleteModal(false)}
      />
    </>
  );
};

export default UserTypeViewModal;
