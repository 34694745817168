let MENUITEMS = [];

const greatGameString = localStorage.getItem("userProfile");
const greatGame = JSON.parse(greatGameString);

const role = greatGame && greatGame.role;

if (role === "ADMIN") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/games`,
          icon: "fa fa-gamepad",
          type: "link",
          active: false,
          selected: false,
          title: "Game",
        },
        {
          path: `/gamewallet`,
          icon: "fa fa-credit-card",
          type: "link",
          active: false,
          selected: false,
          title: "Game Wallet",
        },
        {
          path: `/bankaccount`,
          icon: "fa fa-university",
          type: "link",
          active: false,
          selected: false,
          title: "Bank Account",
        },
        {
          path: `/manage_repeated_booking`,
          icon: "fa fa-repeat",
          type: "link",
          active: false,
          selected: false,
          title: "Repeated Booking",
        },
      ],
    },
  ];
} else if (role === "MASTER-ADMIN") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/cities`,
          icon: "fa fa-map",
          type: "link",
          active: false,
          selected: false,
          title: "Cities",
        },
        {
          path: `/pitchtype`,
          icon: "fa fa-map-signs",
          type: "link",
          active: false,
          selected: false,
          title: "Pitch Types",
        },
        {
          path: `/pitchname`,
          icon: "fa fa-flag-checkered",
          type: "link",
          active: false,
          selected: false,
          title: "Pitch Names",
        },
        {
          path: `/teams`,
          icon: "fa fa-users",
          type: "link",
          active: false,
          selected: false,
          title: "Teams",
        },
        {
          path: `/userlist`,
          icon: "fa fa-address-card",
          type: "link",
          active: false,
          selected: false,
          title: "User List",
        },
        {
          path: `/community`,
          icon: "ti-world",
          type: "link",
          active: false,
          selected: false,
          title: "Community",
        },

        {
          path: `/gamefaq`,
          icon: "ti-comment-alt",
          type: "link",
          active: false,
          selected: false,
          title: "FAQ",
        },
        {
          path: `/manage_repeated_booking`,
          icon: "fa fa-repeat",
          type: "link",
          active: false,
          selected: false,
          title: "Repeated Booking",
        },
      ],
    },
  ];
} else if (role === "PLAYER") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/profile`,
          icon: "ti-comment-alt",
          type: "link",
          active: false,
          selected: false,
          title: "Player Profile",
        },
      ],
    },
  ];
} else if (role === "BUSINESS_PARTNER") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/businesspartnerdashboard`,
          icon: "ti-home",
          type: "link",
          active: false,
          selected: false,
          title: "dashboardname",
        },
        {
          path: `/controlleraccounts`,
          icon: "ti-user",
          type: "link",
          active: false,
          selected: false,
          title: "controlleraccount",
        },
        {
          path: `/businesspartnerparkhistory`,
          icon: "far fa-clock",
          type: "link",
          active: false,
          selected: false,
          title: "parkhistory",
        },
      ],
    },
  ];
} else if (role === "CHILD_BUSINESS_PARTNER") {
  MENUITEMS = [
    {
      Items: [
        {
          path: `/childbusinesspartnerparkhistory`,
          icon: "far fa-clock",
          type: "link",
          active: false,
          selected: false,
          title: "Park History",
        },
      ],
    },
  ];
}

export { MENUITEMS };
