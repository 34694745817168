import React, { useState, useEffect } from "react";
import { Button, Table, Card, Spinner, Pagination } from "react-bootstrap";
import { useSnackbar } from "notistack";
import BankAccountEditModal from "./BankAccountEditModal";
import DeleteBankModal from "./DeleteBankModal";
import BankAccountService from "../../../../../services/bankAccount.service";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation

const BankAccount = () => {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [bankData, setBankData] = useState({});
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPageOptions = [5, 10, 15, 20];
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(data.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const { enqueueSnackbar } = useSnackbar();

  const getBankTypeData = async () => {
    try {
      setLoading(true);
      const response = await BankAccountService.getBankType();
      setData(response.data["data"]);

      setLoading(false);
      setLoadingData(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("GetAll PitchType errrrrr", error);
      setLoading(false);
      setLoadingData(false);
    }
  };
  useEffect(() => {
    getBankTypeData();
  }, []);

  const handleClose = () => {
    setShowAddModal(false);
    setShowDeleteModal(false);
    setIsEdit(false);
    setBankData({});
  };

  const handleEditAccount = async (id) => {
    setBankData(id);
    setShowAddModal(true);
    if (id) {
      const response = await BankAccountService.GetBankTypeSingleId(id);
      const result = response.data.data;
      formik.setFieldValue("accountNumber", result.accountNumber);
      formik.setFieldValue("id", result._id);
      formik.setFieldValue("accountName", result.accountName);
      formik.setFieldValue("type", result.type);
      formik.setFieldValue("bankName", result.bankName);
    } else {
      formik.setFieldValue("accountNumber", "");
      formik.setFieldValue("accountName", "");
      formik.setFieldValue("type", "");
      formik.setFieldValue("bankName", "");
      formik.resetForm();
    }
  };

  const handleDeleteAccount = (_id, bankName) => {
    setDeletingIndex(_id);
    setShowDeleteModal(true);
    setBankData({ id: _id, name: bankName });
  };

  const handleConfirmDelete = async (id) => {
    try {
      const response = await BankAccountService.DeleteBankType(id);
      setShowDeleteModal(false);
      getBankTypeData();
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleFormSubmit = async (values, action) => {
    if (!values.id) {
      try {
        const response = await BankAccountService.CreateBankType(values);
        action.resetForm();
        setShowAddModal(false);
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar("Add Game Community Successfully!", {
            variant: "success",
          });
          formik.resetForm();
          getBankTypeData();
          handleClose();
          action.resetForm();
        } else {
          enqueueSnackbar("User already exists with this email", {
            variant: "error",
            autoHideDuration: 5000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar("User already exists with this email", {
          variant: "error",
        });
        console.log("Add Game Community", error);
      }
    } else {
      try {
        const response = await BankAccountService.UpdateBankType(
          values.id,
          values
        );
        action.resetForm();
        getBankTypeData();
        handleClose();
        setShowAddModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    getBankTypeData();
    handleClose();
  };

  const formik = useFormik({
    initialValues: {
      accountNumber: 0,
      accountName: "",
      type: "",
      bankName: "",
    },
    validationSchema: Yup.object({
      accountNumber: Yup.string().required("Account Number is required"),
      accountName: Yup.string().required("Holder Name is required"),
      type: Yup.string().required("Account Type is required"),
      bankName: Yup.string().required("Bank Name is required"),
    }),
    onSubmit: handleFormSubmit,
  });

  //SEARCH TEXT CHANGES
  const filteredData = searchQuery
    ? data.filter(
        (account) =>
          account.accountNumber.toString().includes(searchQuery) ||
          account.accountName
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          account.type.toLowerCase().includes(searchQuery.toLowerCase()) ||
          account.bankName.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : data;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="page-header">
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">Bank Account</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => {
                setShowAddModal(true);
                setBankData(null);
              }}
            >
              <i className="fe fe-plus me-2"></i>Add Bank Account
            </Button>
          </div>
        </div>
      </div>
      <div>
        <Card style={{ overflow: "hidden", margin: "1%" }}>
          <Card.Header className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h4 style={{ color: "#615f78" }}>Add Bank Account </h4>
            <div className="d-flex align-items-center mt-3 mt-sm-0">
              <input
                type="text"
                placeholder="Search Account..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control"
                style={{ marginRight: "10px" }}
              />
              <Button
                size="sm"
                style={{
                  borderRadius: "5px",
                  fontSize: "12px",
                  padding: "6px",
                }}
                variant="primary"
                onClick={() => setSearchQuery("")}
              >
                Clear
              </Button>
            </div>
          </Card.Header>
          <Card.Body style={{ overflow: "auto" }}>
            {loadingData ? (
              <div style={{ textAlign: "center" }}>
                <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                  Loading...
                </h3>
              </div>
            ) : currentItems && currentItems.length > 0 ? (
              <>
                <Table striped bordered hover>
                  <thead style={{ backgroundColor: "#0DB2F0" }}>
                    <tr style={{ textAlign: "center" }}>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        ID
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Account Number
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Holder Name
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Account Type
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Name Of Bank
                      </th>
                      <th
                        style={{
                          fontWeight: "bold",
                          textAlign: "center",
                          fontSize: "15px",
                        }}
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {currentItems.map((account) => (
                      <tr key={account.id}>
                        <td>{account._id}</td>
                        <td>{account.accountNumber}</td>
                        <td>{account.accountName}</td>
                        <td>{account.type}</td>
                        <td>{account.bankName}</td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Button
                            size="sm"
                            variant="info"
                            onClick={() => handleEditAccount(account._id)}
                          >
                            <i className="fe fe-edit"></i>
                          </Button>
                          <Button
                            size="sm"
                            variant="danger"
                            onClick={() =>
                              handleDeleteAccount(
                                account._id,
                                account?.bankName
                              )
                            }
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                {/* Pagination */}
                <div className="d-flex mt-4" style={{ float: "right" }}>
                  <Pagination>
                    <Pagination.Prev
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                    />
                    {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                      (number) => (
                        <Pagination.Item
                          key={number}
                          active={currentPage === number}
                          onClick={() => paginate(number)}
                        >
                          {number}
                        </Pagination.Item>
                      )
                    )}
                    <Pagination.Next
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                    <div
                      style={{
                        float: "right",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span>&nbsp;&nbsp;Show:</span>
                      <select
                        value={itemsPerPage}
                        onChange={(e) =>
                          handleItemsPerPageChange(parseInt(e.target.value))
                        }
                        style={{ marginLeft: "5px" }}
                      >
                        {itemsPerPageOptions.map((option) => (
                          <option
                            style={{ textAlign: "center" }}
                            key={option}
                            value={option}
                          >
                            {option}
                          </option>
                        ))}
                      </select>
                      <span>&nbsp;&nbsp;items per page</span>
                    </div>
                  </Pagination>
                </div>
              </>
            ) : (
              <div style={{ textAlign: "center" }}>No accounts available</div>
            )}
          </Card.Body>
        </Card>

        {/* Add/Edit Account Modal */}
        <BankAccountEditModal
          show={showAddModal}
          handleClose={handleClose}
          handleSave={formik.handleSubmit}
          isEdit={bankData !== null}
          selectedBankData={bankData}
          formik={formik}
          formikErrors={formik.errors}
        />

        {/* Delete Account Modal */}
        <DeleteBankModal
          show={showDeleteModal}
          handleClose={() => setShowDeleteModal(false)}
          handleConfirmDelete={handleConfirmDelete}
          bankName={bankData ? bankData?.name : ""}
          _id={deletingIndex}
        />
      </div>
    </>
  );
};

export default BankAccount;
