import React, { useState, useEffect } from "react";
import { Button, Card, Table, Modal, Pagination } from "react-bootstrap";
import GameSettingEditModal from "./GameSettingEditModal";
import DeleteGameSettingsModal from "./DeleteGameSettingsModal";
import GameSettingsDetailsModal from "./GameSettingsDetailsModal";
import moment from "moment";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GameSettingsData from "../../../../../services/gameSettings.service";
import { enqueueSnackbar } from "notistack";
import { FieldArray, useFormik } from "formik";
import * as Yup from "yup";

const schema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  dayType: Yup.string().required("Day type is required"),
  startTime: Yup.string().required("Start time is required"),
  // .test(
  //   "Invalid time range. Please select between 6:00 to 23:00.",
  //   function (value) {
  //     if (!value) return false;
  //     const time = value.split(":");
  //     const hours = parseInt(time[0], 10);
  //     const minutes = parseInt(time[1], 10);
  //     return hours >= 6 && hours <= 23 && minutes >= 0 && minutes < 60;
  //   }
  // ),
  endTime: Yup.string().required("End time is required"),
  // .test(
  //   "Invalid time range. Please select between 6:00 to 23:00.",
  //   function (value) {
  //     if (!value) return false;
  //     const time = value.split(":");
  //     const hours = parseInt(time[0], 10);
  //     const minutes = parseInt(time[1], 10);
  //     return hours >= 6 && hours <= 23 && minutes >= 0 && minutes < 60;
  //   }
  // ),
  rules: Yup.array().of(Yup.string().required("Rule is required")),
  important: Yup.array().of(Yup.string().required("Important is required")),
  pitchTypeId: Yup.mixed().required("Pitch type ID is required"),
  pitch: Yup.array().min(1, "Pitch name is required"),
  individualGame: Yup.object().shape({
    targetMargin: Yup.number().required("Target margin is required"),
    price: Yup.number().required("Actual revenue is required"),
    costOfPitch: Yup.number().required("Cost of pitch is required"),
    hostCommission: Yup.number().required("Host commission is required"),
  }),
  fullPitchBooking: Yup.object().shape({
    price: Yup.number().required("Price is required"),
    costOfPitch: Yup.number().required("Cost of pitch is required"),
    profitMargin: Yup.number().required("Profit margin is required"),
    depositPrice: Yup.number().required("Deposit Price is required"),
  }),
  friendlyGame: Yup.object().shape({
    price: Yup.number().required("Price is required"),
    costOfPitch: Yup.number().required("Cost of pitch is required"),
    profitMargin: Yup.number().required("Profit margin is required"),
  }),
});

const pitchTypes = [
  { value: "INDIVIDUAL", label: "INDIVIDUAL" },
  { value: "FRIENDLY", label: "FRIENDLY" },
  { value: "FULL-PITCH", label: "FULL-PITCH" },
];

const GameSettings = () => {
  const [formData, setFormData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedGameSetting, setSelectedGameSetting] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [selectedGameSettingForView, setSelectedGameSettingForView] =
    useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [isEditModal, setIsEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [gameSettingsData, setGameSettingsData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedGameSettings, setSelectedGameSettings] = useState(null);
  const [addNumbers, setAddNumbers] = useState(1);
  const [addRules, setAddRules] = useState(1);
  const [isStartChange, setIsStartChange] = useState(false);
  const [isEndChange, setIsEndChange] = useState(false);
  const [localStartTime, setLocalStartTime] = useState("");
  const [localEndTime, setLocalEndTime] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const itemsPerPageOptions = [5, 10, 15, 20];
  const totalPages = Math.ceil(gameSettingsData.length / itemsPerPage);

  const location = useLocation();
  const { state } = location;
  const { id } = useParams();

  const facilityName = state && state?.facilityName;
  const SubId = state && state?.SubId ? state?.SubId : null;

  const getGameSettingsData = async () => {
    try {
      setIsLoading(true);
      const response = await GameSettingsData.GameSettingsIDForFacility(SubId);
      setGameSettingsData(response.data["data"]);
      setIsLoading(false);
      enqueueSnackbar(response.data.message, {
        variant: "success",
        autoHideDuration: 1000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getGameSettingsData();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  const handleClose = () => {
    setIsEditMode(false);
    setShowModal(false);
    setSelectedGameSettings(null);
  };

  const handlUpdate = async (id) => {
    setSelectedGameSettings(id);
    setIsEditMode(true);
    setShowModal(true);
    if (id) {
      setIsEditMode(true);
      setShowModal(true);
      try {
        const response = await GameSettingsData.GetGameSettingsSingleId(id);
        const result = response.data.data;
        const ftime = moment
          .utc(result.startTime, "HH:mm")
          .local()
          .format("HH:mm");
        const etime = moment
          .utc(result.endTime, "HH:mm")
          .local()
          .format("HH:mm");

        setLocalStartTime(ftime);
        setLocalEndTime(etime);
        formik.setFieldValue("id", result._id);
        formik.setFieldValue("name", result.name);
        formik.setFieldValue("dayType", result.dayType);
        formik.setFieldValue("startTime", result.startTime);
        formik.setFieldValue("endTime", result.endTime);
        formik.setFieldValue("rules", result.rules);
        formik.setFieldValue("important", result.important);
        formik.setFieldValue("pitchTypeId", result.pitchTypeId);
        // formik.setFieldValue("gameType", result.gameType);
        formik.setFieldValue(
          "startDate",
          moment(result.startDate).format("DD/MM/YYYY")
        );
        formik.setFieldValue(
          "endDate",
          moment(result.endDate).format("DD/MM/YYYY")
        );

        formik.setFieldValue(
          "pitch",
          result.pitch.map((pitchItem) => ({
            value: pitchItem._id,
            label: pitchItem.name,
          }))
        );

        console.log("result.gameType", result.gameType);

        formik.setFieldValue(
          "gameType",
          result.gameType.map((type) => ({
            value: type,
            label: type,
          }))
        );

        setAddRules(result.rules.length);
        setAddNumbers(result.important.length);

        formik.setFieldValue("individualGame", {
          ...formik.values.individualGame,
          targetMargin: result?.individualGame?.targetMargin || 0,
          price: result?.individualGame?.price || 0,
          costOfPitch: result?.individualGame?.costOfPitch || 0,
          hostCommission: result?.individualGame?.hostCommission || 0,
        });

        formik.setFieldValue("isDeposit", result?.isDeposit);
        formik.setFieldValue("isAllowCashPayment", result?.isAllowCashPayment);
        formik.setFieldValue("isAllowOpenInvitation", result?.isAllowOpenInvitation);
        formik.setFieldValue("openInvitationCount", result?.openInvitationCount);

        formik.setFieldValue("fullPitchBooking", {
          ...formik.values.fullPitchBooking,
          price: result?.fullPitchBooking?.price || 0,
          costOfPitch: result?.fullPitchBooking?.costOfPitch || 0,
          profitMargin: result?.fullPitchBooking?.profitMargin || 0,
          depositPrice: result?.fullPitchBooking?.depositPrice || 0,
        });

        formik.setFieldValue("friendlyGame", {
          ...formik.values.friendlyGame,
          price: result?.friendlyGame?.price || 0,
          costOfPitch: result?.friendlyGame?.costOfPitch || 0,
          profitMargin: result?.friendlyGame?.profitMargin || 0,
        });
      } catch (error) {
        console.log("Error fetching game settings for editing:", error);
      }
    } else {
      formik.resetForm();
    }
  };

  const getTimeZoneTime = (time) => {
    const inputDate = new Date(moment(time, "DD.MM.YYYY HH:mm").valueOf());
    let newTime = "";

    const adjustDateByGMT = (date, gmtOffset) => {
      const sign = gmtOffset[0];
      const [hours, minutes] = gmtOffset?.match(/[+-]?\d{2}/g).map(Number);
      if (sign === "+") {
        newTime = moment(date)
          .add(hours, "hours")
          .add(minutes, "minutes")
          .format("DD/MM/YYYY HH:mm:ss");
      } else if (sign === "-") {
        newTime = moment(date)
          .subtract(hours - 1, "hours")
          .subtract(minutes, "minutes")
          .format("DD/MM/YYYY HH:mm:ss");
      }
    };

    const timezonehours = String(inputDate).split(" ");
    if (timezonehours[5]?.slice(3)) {
      adjustDateByGMT(inputDate, String(timezonehours[5]?.slice(3)));
    }

    return moment(newTime, "DD/MM/YYYY HH:mm:ss");
  };

  const handleSubmit = async (values, action) => {
    const {
      name,
      dayType,
      startTime,
      endTime,
      rules,
      important,
      pitchTypeId,
      pitch,
      individualGame,
      isDeposit,
      isAllowCashPayment,
      isAllowOpenInvitation,
      openInvitationCount,
      fullPitchBooking,
      friendlyGame,
      gameType,
      startDate,
      endDate,
    } = values;

    const ids = pitch.map((id) => (id.value ? id.value : id));
    const gameTypeIds = gameType.map((id) => (id.value ? id.value : id));

    setLoadingData(true);
    let stime;
    let etime;
    if (isStartChange) {
      stime = moment(startTime, "HH:mm").utc().format("HH:mm");
    } else {
      stime = startTime;
    }

    if (isEndChange) {
      etime = moment(endTime, "HH:mm").utc().format("HH:mm");
    } else {
      etime = endTime;
    }

    if (!values.id) {
      try {
        const response = await GameSettingsData.CreateGameSettings(id, {
          name,
          dayType,
          startTime: stime,
          endTime: etime,
          rules,
          important,
          pitchTypeId,
          pitch: ids,
          individualGame,
          isDeposit,
          isAllowCashPayment,
          isAllowOpenInvitation,
          openInvitationCount,
          fullPitchBooking,
          friendlyGame,
          ...(gameTypeIds && { gameType: gameTypeIds }),
          startDate: moment.utc(startDate, "DD/MM/YYYY").valueOf(),
          endDate: moment.utc(endDate, "DD/MM/YYYY").valueOf(),
        });

        formik.setValues({
          ...formik.values,
          name: response.data.name,
          dayType: response.data.dayType,
          startTime: response.data.startTime,
          endTime: response.data.endTime,
          // Update other fields as needed
        });
        if (response.status === 200 || response.status === 201) {
          enqueueSnackbar(response.data.message, {
            variant: "success",
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
          formik.resetForm();
          getGameSettingsData();
          handleClose();
          setLoadingData(false);
          action.resetForm();
        } else {
          setLoadingData(false);
          enqueueSnackbar(response.data.message, {
            variant: "error",
            autoHideDuration: 4000,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          });
        }
      } catch (error) {
        enqueueSnackbar("User already exists with this email", {
          variant: "error",
        });
        console.log("Add Game Community", error);
      }
    } else {
      try {
        const response = await GameSettingsData.UpdateGameSettings(values.id, {
          name,
          dayType,
          startTime: stime,
          endTime: etime,
          rules,
          important,
          pitchTypeId,
          pitch: ids,
          individualGame,
          isDeposit,
          isAllowCashPayment,
          isAllowOpenInvitation,
          openInvitationCount,
          fullPitchBooking,
          friendlyGame,
          ...(gameTypeIds && { gameType: gameTypeIds }),
          startDate: moment(startDate, "DD/MM/YYYY").valueOf(),
          endDate: moment(endDate, "DD/MM/YYYY").valueOf(),
        });

        action.resetForm();
        getGameSettingsData();
        setShowModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 5000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
    setIsEndChange(false);
    setIsStartChange(false);
    handleClose();
    setLoadingData(false);
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      dayType: "",
      startTime: "",
      endTime: "",
      startDate: "",
      endDate: "",
      rules: [""],
      important: [""],
      pitchTypeId: "",
      pitch: [""],
      gameType: "",
      isDeposit: false,
      isAllowCashPayment: false,
      isAllowOpenInvitation: false,
      openInvitationCount: 0,
      individualGame: {
        targetMargin: 0,
        price: 0,
        costOfPitch: 0,
        hostCommission: 0,
      },
      fullPitchBooking: {
        price: 0,
        costOfPitch: 0,
        profitMargin: 0,
        depositPrice: 0,
      },
      friendlyGame: {
        price: 0,
        costOfPitch: 0,
        profitMargin: 0,
      },
    },
    onSubmit: handleSubmit,
    validationSchema: schema,
  });

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setShowModal(false);
    setFormData(null);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setSelectedGameSetting(null);
  };

  const handleEditClick = (gameSetting) => {
    setFormData(gameSetting);
    setShowModal(true);
    setShowEditModal(true);
    setIsEditModal(true);
  };

  const handleDeleteClick = (_id) => {
    setShowDeleteModal(true);
    setSelectedGameSettings(_id);
  };

  const handleConfirmDelete = async (id) => {
    setDeleteLoading(true);
    try {
      const response = await GameSettingsData.DeleteGameSettings(id);
      setShowDeleteModal(false);
      getGameSettingsData();
      enqueueSnackbar(response.data.message, {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
    setDeleteLoading(false);
  };

  const handleViewDetailsClick = (gameSetting) => {
    setSelectedGameSettingForView(gameSetting);
    setShowViewModal(true);
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handleViewGamesClick = (Subid, facilityName) => {
    navigate(`/cities/facilitise/details/gamesettings/game/${Subid}`, {
      state: {
        GameSettingName: facilityName,
        GameId: Subid,
      },
    });
  };

  const handlePitchChange = (data) => {
    formik.setFieldValue("pitchTypeId", data.target.value);
  };

  const handlePitchNameChange = (value) => {
    formik.setFieldValue("pitch", value);
  };

  const handlePitchTypeChange = (value) => {
    formik.setFieldValue("gameType", value);
  };

  // const handleTimeChnage = (selectedTime) => {
  //     const timeString = selectedTime.format('hh:mm');
  //     formik.setFieldValue('startTime', timeString);
  // };
  //TEXT SEARCH CHNGES
  const filteredGameSettings = gameSettingsData.filter((item) => {
    return (
      item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.pitchTypeId.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      item.pitch.some((pitchItem) =>
        pitchItem.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    );
  });

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  const handleDateChange = (date, type) => {
    if (type === "start") {
      formik.setFieldValue(
        "startDate",
        moment(new Date(date)).startOf().format("DD/MM/YYYY")
      );
    } else {
      formik.setFieldValue(
        "endDate",
        moment(new Date(date)).startOf().format("DD/MM/YYYY")
      );
    }
  };

  return (
    <>
      <div
        className="page-header"
        style={{ marginBottom: "0px", marginTop: "10px" }}
      >
        <button
          size="sm"
          className="btn btn-primary"
          style={{ borderRadius: "20px" }}
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <div>
          <h2 className="main-content-title tx-24 mg-b-5">
            Facilities Details
          </h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              style={{
                marginRight: "10px",
                color: "whitesmoke",
                fontWeight: "bold",
                borderRadius: "5px",
              }}
              className="my-2 btn-icon-text"
              onClick={() => handleViewGamesClick(SubId, facilityName)}
            >
              <i className="fa fa-eye me-2" aria-hidden="true"></i>View Game
            </Button>

            <Button
              variant="primary"
              type="button"
              style={{ borderRadius: "5px" }}
              className="my-2 btn-icon-text"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <i className="fe fe-plus me-2"></i>Create Game Settings
            </Button>
          </div>
        </div>
      </div>

      <div
        className="col-lg-12 mb-3"
        style={{ paddingRight: "0px", paddingLeft: "0px" }}
      >
        <Card style={{ overflow: "hidden" }}>
          <Card.Header className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
            <h4 style={{ color: "#615f78" }}>{facilityName}</h4>
            <div className="d-flex align-items-center mt-3 mt-sm-0">
              <input
                type="text"
                placeholder="Search Game Setting..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="form-control"
                style={{ marginRight: "10px" }}
              />
              <Button
                variant="primary"
                onClick={() => setSearchQuery("")}
                size="sm"
                style={{
                  borderRadius: "5px",
                  fontSize: "12px",
                  padding: "6px",
                }}
              >
                Clear
              </Button>
            </div>
          </Card.Header>
          <div className="col-lg-12 mb-3">
            <div className="d-flex">
              <div className="col-lg-12 mb-3">
                <Card.Body style={{ overflow: "auto" }}>
                  {isLoading ? (
                    <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                      Loading...
                    </h3>
                  ) : gameSettingsData && gameSettingsData.length > 0 ? (
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>Game Setting Id</th>
                          {/* <th>Date</th> */}
                          <th>Game Setting Name</th>
                          <th>Pitch Type</th>
                          <th>Pitch Name</th>
                          <th>Start-End</th>
                          <th>Day Type</th>
                          <th>Actions</th>
                        </tr>
                      </thead>

                      <tbody>
                        {filteredGameSettings
                          .slice(
                            (currentPage - 1) * itemsPerPage,
                            currentPage * itemsPerPage
                          )
                          .map((item) => (
                            <tr style={{ textAlign: "center" }} key={item._id}>
                              <td>{item._id}</td>
                              {/* <td>{moment(item.updatedAt).format('DD/MM/YYYY')}</td> */}
                              <td>{item.name}</td>
                              <td>{item.pitchTypeId.name}</td>
                              <td>
                                {item.pitch.map((pitchItem, index) => (
                                  <React.Fragment key={pitchItem._id}>
                                    {index > 0 && ", "}
                                    <span>{pitchItem.name}</span>
                                  </React.Fragment>
                                ))}
                              </td>
                              {/* <td style={{ width: '150px' }}>{item.startTime} - {item.endTime}</td> */}
                              <td style={{ width: "150px" }}>
                                {moment
                                  .utc(item.startTime, "HH:mm")
                                  .local()
                                  .format("HH:mm")}{" "}
                                -{" "}
                                {moment
                                  .utc(item.endTime, "HH:mm")
                                  .local()
                                  .format("HH:mm")}
                              </td>

                              <td>{item.dayType}</td>
                              <td
                                style={{
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  border: "none",
                                  width: "15%",
                                }}
                              >
                                <Button
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  variant="info"
                                  onClick={() => handlUpdate(item._id)}
                                >
                                  <i className="fe fe-edit"></i>
                                </Button>
                                <Button
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  variant="danger"
                                  onClick={() => handleDeleteClick(item._id)}
                                >
                                  <i className="fa fa-trash"></i>
                                </Button>
                                <Button
                                  style={{ margin: "5px" }}
                                  size="sm"
                                  variant="primary"
                                  onClick={() => handleViewDetailsClick(item)}
                                >
                                  <i className="fa fa-eye"></i>
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  ) : (
                    <p style={{ textAlign: "center" }}>
                      No GameSettings available. Click the "Create Game
                      Settings" button to create.
                    </p>
                  )}

                  {gameSettingsData && gameSettingsData.length > 0 && (
                    <>
                      {/* Pagination */}
                      <div className="d-flex mt-4" style={{ float: "right" }}>
                        <Pagination>
                          <Pagination.Prev
                            onClick={() => paginate(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                          {Array.from(
                            { length: totalPages },
                            (_, i) => i + 1
                          ).map((number) => (
                            <Pagination.Item
                              key={number}
                              active={currentPage === number}
                              onClick={() => paginate(number)}
                            >
                              {number}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                          <div
                            style={{
                              float: "right",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>&nbsp;&nbsp;Show:</span>
                            <select
                              value={itemsPerPage}
                              onChange={(e) =>
                                handleItemsPerPageChange(
                                  parseInt(e.target.value)
                                )
                              }
                              style={{ marginLeft: "5px" }}
                            >
                              {itemsPerPageOptions.map((option) => (
                                <option
                                  style={{ textAlign: "center" }}
                                  key={option}
                                  value={option}
                                >
                                  {option}
                                </option>
                              ))}
                            </select>
                            <span>&nbsp;&nbsp;items per page</span>
                          </div>
                        </Pagination>
                      </div>
                    </>
                  )}

                  {/* Dropdown for selecting items per page */}
                </Card.Body>
              </div>
            </div>
          </div>
        </Card>
      </div>
      {showModal && (
        <GameSettingEditModal
          showModal={showModal}
          setShowModal={setShowModal}
          handleClose={handleCloseEditModal}
          handleSave={formik.handleSubmit}
          setFormData={setFormData}
          selectedDateRange={selectedDateRange}
          formik={formik}
          selectedGameSettings={selectedGameSettings}
          isEditMode={isEditMode}
          formikErrors={formik.errors}
          setIsEditMode={setIsEditMode}
          FieldArray={FieldArray}
          handlePitchChange={handlePitchChange}
          handlePitchNameChange={handlePitchNameChange}
          handlePitchTypeChange={handlePitchTypeChange}
          addNumbers={addNumbers}
          setAddNumbers={setAddNumbers}
          addRules={addRules}
          setAddRules={setAddRules}
          // handleTimeChnage={handleTimeChnage}
          isStartChange={isStartChange}
          isEndChange={isEndChange}
          setIsStartChange={setIsStartChange}
          setIsEndChange={setIsEndChange}
          setLocalStartTime={setLocalStartTime}
          localStartTime={localStartTime}
          setLocalEndTime={setLocalEndTime}
          localEndTime={localEndTime}
          loadingData={loadingData}
          handleDateChange={handleDateChange}
          pitchTypes={pitchTypes}
        />
      )}

      {showDeleteModal && (
        <DeleteGameSettingsModal
          show={showDeleteModal}
          handleClose={handleCloseDeleteModal}
          handleConfirmDelete={handleConfirmDelete}
          _id={selectedGameSettings}
          pitchName={selectedGameSettings ? selectedGameSettings.name : ""}
          deleteLoading={deleteLoading}
        />
      )}
      {showViewModal && (
        <GameSettingsDetailsModal
          show={showViewModal}
          handleClose={() => setShowViewModal(false)}
          selectedGameSettingForView={selectedGameSettingForView}
        />
      )}

      {/* View Modal */}
    </>
  );
};

export default GameSettings;
