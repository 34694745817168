import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Pagination,
  Spinner,
  Table,
  Form,
} from "react-bootstrap";
import CreateFacilitiseModal from "./FacilitiseEditModal";
import DeleteFacilitiseModal from "./DeleteFacilitiseModal";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { Field, useFormik } from "formik";
import * as Yup from "yup";
import { enqueueSnackbar } from "notistack";
import FacilityData from "../../../../../services/facility.service";
import CityData from "../../../../../services/city.service";
import { fromLatLng, setDefaults, setKey } from "react-geocode";
import { YouTube } from "@material-ui/icons";
import UserListData from "../../../../../services/userList.service";
import moment from "moment";
import Switch from "../Cities/Switch";

const Facilitise = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showCreateGameModal, setShowCreateGameModal] = useState(false);
  const [facilitiseData, setFacilitiseData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [selectedItemData, setSelectedItemData] = useState(null);
  const [scroll, setScroll] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isNewImage, setIsNewImage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPageOptions = [5, 10, 15, 20];
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const location = useLocation();
  const { state } = location;
  const cityName = state && state?.cityName ? state?.cityName : "Unknown City";
  const cityId = state && state?.cityId ? state?.cityId : null;

  const navigate = useNavigate();

  const getMainFacilityData = async () => {
    try {
      setIsLoading(true);
      const response = await CityData.CityIDForFacility(cityId);
      setFacilitiseData(response.data["data"]);
      setIsLoading(false);
      setLoadingData(false);
      enqueueSnackbar("Get Facilities successfully", {
        variant: "success",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setIsLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getMainFacilityData();
  }, []);

  const getMainUserListData = async () => {
    try {
      setIsLoading(true);
      const response = await UserListData.getAdminListData(search);
      setData(response.data["data"]);
      setIsLoading(false);
      setLoadingData(false);
    } catch (error) {
      console.log("getMainCategory errrrrr", error);
      setIsLoading(false);
      setLoadingData(false);
    }
  };

  useEffect(() => {
    getMainUserListData();
  }, [search]);

  const fetchFacilitise = async () => {
    setIsLoading(false);
    try {
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching facilitise:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (scroll) {
      window.scrollTo(0, 0);
    }
  }, [scroll]);

  useEffect(() => {
    fetchFacilitise();
  }, [currentPage]);

  const handleNameChange = (data) => {
    const input = data.target.value.split("=");
    const firstName = input[0];
    const lastName = input[1];
    formik.setFieldValue("firstName", firstName);
    formik.setFieldValue("lastName", lastName);
  };

  const createFormData = (values) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("cityId", cityId);
    if (values.coordinates) {
      formData.append("isPublish", values.isPublish);
    }
    formData.append(
      "coordinates",
      values.coordinates
        ? JSON.stringify(values.coordinates)
        : JSON.stringify(values.location.coordinates)
    );
    if (isNewImage) {
      formData.append("file", values.file);
    }
    formData.append("address", values.address);
    // formData.append("facilityOwner", values.facilityOwner);

    if (values.facilityOwner) {
      formData.append("facilityOwner", values.facilityOwner);
    } else {
      formData.append("facilityOwner", null);
    }

    return formData;
  };

  const handleFormSubmit = async (values, action) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("cityId", cityId);
    // formData.append("coordinates", JSON.stringify(values.coordinates[0]));
    formData.append("coordinates", JSON.stringify(values.coordinates));
    formData.append("file", values.file);
    formData.append("address", values.address);
    formData.append("isPublish", values.isPublish);
    // formData.append("facilityOwner", values.facilityOwner);
    if (values.facilityOwner) {
      formData.append("facilityOwner", values.facilityOwner);
    } else {
      formData.append("facilityOwner", null);
    }

    if (!values.id) {
      try {
        const response = await FacilityData.CreateFacility(formData);
        formik.resetForm();
        getMainFacilityData();
        handleClose();
        action.resetForm();
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        console.log("e", e);
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      }
    } else {
      try {
        const formDataUpdate = createFormData(values);
        const response = await FacilityData.UpdateFacilityDataRequest(
          values.id,
          formDataUpdate
        );
        action.resetForm();
        getMainFacilityData();
        setShowCreateGameModal(false);
        enqueueSnackbar(response.data.message, {
          variant: "success",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
      } catch (e) {
        enqueueSnackbar(e.response.data.message, {
          variant: "error",
          autoHideDuration: 4000,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "right",
          },
        });
        console.log("e", e);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      name: selectedItemData ? selectedItemData.name : "",
      cityId: "",
      file: "",
      coordinates: [0, 0],
      address: selectedItemData ? selectedItemData.address : "",
      facilityOwner: "",
      isPublish: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Facility name is required"),
      file: Yup.mixed().required("Please select image File"),
      isPublish: Yup.boolean(),
    }),
    onSubmit: handleFormSubmit,
  });

  const handleClose = () => {
    setIsEditing(false);
    setShowCreateGameModal(false);
    setSelectedItemData(null);
  };

  const handleEditClick = async (id) => {
    setShowCreateGameModal(true);
    setIsEditing(true);
    setSelectedItemData(id);
    if (id) {
      try {
        const response = await FacilityData.GetFacilitySingleId(id);
        const result = response.data.data;
        if (result) {
          const facilityData = result;
          formik.setFieldValue("name", result.name);
          formik.setFieldValue(
            "coordinates",
            facilityData.location ? facilityData.location.coordinates : ""
          );
          formik.setFieldValue("file", facilityData.imagelink);
          formik.setFieldValue("isPublish", facilityData.isPublish);
          formik.setFieldValue("address", facilityData.address);
          formik.setFieldValue("cityId", facilityData.cityId);
          formik.setFieldValue("id", facilityData._id);
          formik.setFieldValue("facilityOwner", facilityData.facilityOwner._id);
          formik.setFieldValue(
            "firstName",
            facilityData.facilityOwner?.firstName
          );
          formik.setFieldValue(
            "lastName",
            facilityData.facilityOwner?.lastName
          );
        } else {
          console.error("No data found for the provided facility ID");
        }
      } catch (error) {
        console.error("Error fetching facility data:", error);
      }
    } else {
      formik.resetForm();
    }
  };

  const handleDeleteClick = (facilityId, facilityName) => {
    setIsDeleting(true);
    setDeletingIndex(facilityId);
    setSelectedItemData({ id: facilityId, name: facilityName });
  };

  const handleDeleteConfirmed = async (id) => {
    try {
      const response = await FacilityData.DeleteFacilityDataRequest(id);
      setIsDeleting(false);
      getMainFacilityData();

      enqueueSnackbar(response.data.message, {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    } catch (e) {
      console.log(e.response.data.message);
      enqueueSnackbar("Error deleting city", {
        variant: "error",
        autoHideDuration: 4000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      });
    }
  };

  const handleViewDetailsClick = (_id, name, pitchType, pitchName) => {
    navigate(`/cities/facilitise/details/gamesettings/${_id}`, {
      state: {
        facilityName: name,
        pitchType: pitchType,
        pitchName: pitchName,
        SubId: _id,
      },
    });
  };

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Logic for displaying current items
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = facilitiseData.slice(indexOfFirstItem, indexOfLastItem);

  //REACT TABLE ADD HERE
  const filteredData = searchQuery
    ? facilitiseData.filter((facility) =>
        facility.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : facilitiseData;

  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const handleClearSearch = () => {
    setSearchQuery("");
    setSearch("");
  };

  const handleItemsPerPageChange = (value) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset current page when changing items per page
  };

  return (
    <>
      <div
        className="page-header"
        style={{ marginBottom: "0px", marginTop: "10px" }}
      >
        <button
          size="sm"
          className="btn btn-primary"
          style={{ borderRadius: "20px" }}
          onClick={() => navigate(-1)}
        >
          <i className="fa fa-arrow-left" aria-hidden="true"></i>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          margin: "10px",
        }}
      >
        <div className="">
          <h2 className="main-content-title tx-24 mg-b-5">Facilities</h2>
        </div>
        <div className="d-flex">
          <div className="justify-content-center">
            <Button
              variant="primary"
              type="button"
              className="my-2 btn-icon-text"
              onClick={() => setShowCreateGameModal(true)}
              style={{ borderRadius: "5px" }}
            >
              <i className="fe fe-plus me-1"></i>Create Facility
            </Button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12">
          <Card style={{ overflow: "hidden", marginBottom: "20px" }}>
            <Card.Header className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <h4 style={{ color: "#615f78" }}>{cityName} </h4>
              <div className="d-flex align-items-center mt-3 mt-sm-0">
                <input
                  type="text"
                  placeholder="Search Facility..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="form-control"
                  style={{ marginRight: "10px" }}
                />
                <Button
                  size="sm"
                  style={{
                    borderRadius: "5px",
                    fontSize: "12px",
                    padding: "6px",
                  }}
                  variant="primary"
                  onClick={handleClearSearch}
                >
                  Clear
                </Button>
              </div>
            </Card.Header>
            <Card.Body style={{ overflow: "auto" }}>
              {loadingData ? (
                <div style={{ textAlign: "center" }}>
                  {/* <Spinner animation="border" role="status">
                                    <span className="sr-only">Loading...</span>
                                </Spinner> */}
                  <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
                    Loading...
                  </h3>
                </div>
              ) : currentItems && currentItems.length > 0 ? (
                <Table striped bordered hover>
                  <thead>
                    <tr style={{ textAlign: "center" }}>
                      <th>Facility ID</th>
                      {/* <th>Date</th> */}
                      <th>Facility Name</th>
                      <th>Location</th>
                      <th>Image</th>
                      <th>Action</th>
                      <th>Publish</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr style={{ textAlign: "center" }}>
                        {imageLoading && (
                          <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                          </Spinner>
                        )}
                      </tr>
                    ) : (
                      currentItems.map((facility, index) => (
                        <tr style={{ textAlign: "center" }} key={index}>
                          <td>{facility._id}</td>
                          {/* <td>{moment(facility.createdAt).format('DD/MM/YYYY')}</td> */}
                          <td>{facility.name}</td>
                          <td>{facility.address}</td>
                          <td>
                            <img
                              src={facility.imagelink}
                              onLoad={() => setImageLoading(false)}
                              alt="Game Image"
                              style={{ width: "80px" }}
                            />
                          </td>

                          <td
                            style={{
                              justifyContent: "center",
                              alignItems: "center",
                              gap: "10px",
                              border: "none",
                              width: "15%",
                            }}
                          >
                            <Button
                              style={{ margin: "5px" }}
                              size="sm"
                              variant="info"
                              onClick={() => handleEditClick(facility._id)}
                            >
                              <i className="fe fe-edit"></i>
                            </Button>

                            <Button
                              style={{ margin: "5px" }}
                              size="sm"
                              variant="danger"
                              onClick={() =>
                                handleDeleteClick(facility._id, facility.name)
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </Button>
                            <Button
                              style={{ margin: "5px" }}
                              size="sm"
                              variant="primary"
                              onClick={() =>
                                handleViewDetailsClick(
                                  facility._id,
                                  facility.name
                                )
                              }
                            >
                              <i className="fa fa-eye"></i>
                            </Button>
                          </td>
                          <td>
                            <Switch
                              value="facility"
                              type="switch"
                              id={`publish-switch-${facility._id}`}
                              checked={facility.isPublish}
                              cityID={facility?._id}
                              cityName={facility?.name}
                              data={createFormData(facility)}
                              getMainCityData={getMainFacilityData}
                              style={{
                                margin: 0,
                                transform: "scale(1.2)",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </Table>
              ) : (
                <p style={{ textAlign: "center" }}>
                  No Facilities available. Click the "Create New Facility"
                  button to create.
                </p>
              )}
              {/* <div className="d-flex justify-content-center mt-4">
                <ul className="pagination">
                  {Array.from({
                    length: Math.ceil(facilitiseData.length / itemsPerPage),
                  }).map((_, index) => (
                    <li
                      key={index}
                      className={`page-item ${currentPage === index + 1 ? "active" : ""
                        }`}
                    >
                      <button
                        onClick={() => paginate(index + 1)}
                        className="page-link"
                      >
                        {index + 1}
                      </button>
                    </li>
                  ))}
                </ul>
              </div> */}
              {/* Pagination */}
              <div className="d-flex mt-4" style={{ float: "right" }}>
                <Pagination>
                  <Pagination.Prev
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (number) => (
                      <Pagination.Item
                        key={number}
                        active={currentPage === number}
                        onClick={() => paginate(number)}
                      >
                        {number}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                  <div
                    style={{
                      float: "right",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <span>&nbsp;&nbsp;Show:</span>
                    <select
                      value={itemsPerPage}
                      onChange={(e) =>
                        handleItemsPerPageChange(parseInt(e.target.value))
                      }
                      style={{ marginLeft: "5px" }}
                    >
                      {itemsPerPageOptions.map((option) => (
                        <option
                          style={{ textAlign: "center" }}
                          key={option}
                          value={option}
                        >
                          {option}
                        </option>
                      ))}
                    </select>
                    <span>&nbsp;&nbsp;&nbsp;items per page</span>
                  </div>
                </Pagination>
              </div>

              {/* Dropdown for selecting items per page */}
            </Card.Body>
          </Card>
          <CreateFacilitiseModal
            show={showCreateGameModal}
            handleClose={() => setShowCreateGameModal(false)}
            handleSave={formik.handleSubmit}
            isEdit={isEditing}
            facilitiseSelect={selectedItemData}
            facilitise={facilitiseData}
            setFacilitise={setFacilitiseData}
            formik={formik}
            setIsEditing={setIsEditing}
            data={data}
            handleNameChange={handleNameChange}
            setIsNewImage={setIsNewImage}
          />

          <DeleteFacilitiseModal
            show={isDeleting}
            handleClose={() => setIsDeleting(false)}
            handleDeleteConfirmed={handleDeleteConfirmed}
            _id={deletingIndex}
            facilityName={selectedItemData ? selectedItemData.name : ""}
          />
        </div>
      </div>
    </>
  );
};

export default Facilitise;
