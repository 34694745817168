import React from "react";
import { Modal, Button } from "react-bootstrap";

const TerminateModal = ({
  showDeleteModal,
  confirmDelete,
  handleClose,
  userId,
  teamName,
}) => {
  return (
    <Modal show={showDeleteModal} onHide={handleClose} centered>
      <Modal.Header>
        <Modal.Title>Confirm Terminate Team</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to terminate <strong>{teamName}</strong> ?
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={handleClose}
          style={{ borderRadius: "10px" }}
        >
          Cancel
        </Button>
        <Button
          variant="danger"
          onClick={() => confirmDelete(userId)}
          style={{ borderRadius: "10px" }}
        >
          Delete
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TerminateModal;
