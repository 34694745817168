import serverCall from "../serverCall";

//GetAllCommunityData
const GetAllCommunityData = async (page) => {
  try {
    const response = await serverCall.get(
      `/community/getall?page=${page}&limit=${6}`
    );
    return response;
  } catch (error) {
    console.log("GetCommunityData error", error);
  }
};

//CreateCommunityData
const CreateCommunityData = async (name) => {
  const response = await serverCall.post(`/community/admin/create`, name);
  return response;
};

//GetCommunityDataSingleId
const GetCommunityDataSingleId = async (id) => {
  try {
    const response = await serverCall.get(`/community/one/${id}`);
    return response;
  } catch (error) {
    console.log("CommunityDataSingleId Api error", error);
  }
};

//DeleteCommunityData
const DeleteCommunityData = async (id) => {
  try {
    const response = await serverCall.delete(`/community/admin/delete/${id}`);
    return response;
  } catch (error) {
    console.log("DeleteCommunityData Api error", error);
  }
};

//UpdateCommunityData
const UpdateCommunityData = async (id, name) => {
  try {
    const response = await serverCall.put(
      `/community/admin/update/${id}`,
      name
    );
    return response;
  } catch (error) {
    console.log("UpdateCommunityData Api error", error);
  }
};

const CommunityData = {
  GetAllCommunityData,
  CreateCommunityData,
  DeleteCommunityData,
  UpdateCommunityData,
  GetCommunityDataSingleId,
};

export default CommunityData;
