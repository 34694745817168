import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Col } from "react-bootstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";

const TeamEditModal = (props) => {
  const { show, handleClose, handleSave, isEdit, formik, formikErrors } = props;

  return (
    <Modal show={show} onHide={handleClose} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          {isEdit ? "Edit Team Name" : "Create Team Name"}
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div style={{ justifyContent: "center" }}>
            <Form.Group controlId="teamName" style={{}}>
              <Form.Label>Team Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Team Name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontWeight: "bolder",
                }}
              />
              {/* {!isEdit && formik.touched.name && formikErrors.name && (
                                <div className="error-message text-danger">{formikErrors.name}</div>
                            )} */}
            </Form.Group>
            {/* <Form.Group controlId="captainName" style={{ marginTop: "10px" }}>
                            <Form.Label>Captain Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Captain Name"
                                name='captainName'
                                value={formik.values.captainName}
                                // onChange={formik.handleChange}
                                style={{ width: "100%", textAlign: "center", fontWeight: "bolder" }}
                            />
                            {!isEdit && formik.touched.captainName && formikErrors.captainName && (
                                <div className="error-message text-danger">{formikErrors.captainName}</div>
                            )}
                        </Form.Group> */}
          </div>
        </Modal.Body>

        <Modal.Footer style={{ justifyContent: "center" }}>
          <Button
            variant="primary"
            type="submit"
            style={{ borderRadius: "10px" }}
          >
            {isEdit ? "Update Team-Name" : "Add Team-Name"}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default TeamEditModal;
